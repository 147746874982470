import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { menuIcons } from "../common/assats";
import "./../styles/homePage/menu.scss";
import { useTranslation } from "react-i18next";
import { useUser } from "../common/contexts/UserContext";
import { Role } from "../common/consts/roles";
import Cookies from "js-cookie";
import { getFirstLettersOfFirstTwoWords } from "../common/function/getNameShort";
import Avatar from "../components/Avatar";
import { NavigationPaths } from "../common/consts/navigationPaths";

const userMenuAccess: Record<Role, string[]> = {
  ClinicalUser: [
    "patients",
    "about",
    "support",
    "contact",
    "settings",
    "logout",
  ],
  NonClinicalUser: ["about", "support", "contact", "settings", "logout"],
  SiteAdmin: [
    "dashboard",
    "patients",
    "users",
    "about",
    "support",
    "contact",
    "settings",
    "logout",
  ],
  SuperAdmin: [
    "dashboard",
    "users",
    "sites",
    "devices",
    "fota",
    "about",
    "support",
    "contact",
    "settings",
    "logout",
  ],
};

const MenuSideBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const { user, setUser } = useUser();
  const [mounted, setMounted] = useState(false);

  const hasAccess = (component: string): boolean => {
    return (
      user?.role.some((role) => userMenuAccess[role]?.includes(component)) ??
      false
    );
  };

  const logout = () => {
    setUser(undefined);
    Cookies.remove("access_token");
  };

  const renderMenuItem = (component: string, path: string, icon: string) => {
    if (!hasAccess(component)) return null;

    const clearedPath = path.split("/")[2];
    const isActive = location.pathname.includes(clearedPath);
    return (
      <Link className="link-style" to={path}>
        <div className="item">
          <div className="item-icon">
            <img src={icon} />
          </div>
          {!collapsed && (
            <span className={`menu-text ${isActive ? "active-tab" : ""}`}>{t(`menu.${component}`)}</span>
          )}
        </div>
      </Link>
    );
  };

  return (
    <div
      className={`menu-side-bar-main-container ${
        !mounted ? "disableAnimation" : collapsed ? "collapsed" : "full"
      }`}
    >
      <div className="menu-headline">
        <h5 className="text">{t("menu.main")}</h5>
        <div
          onClick={() => {
            setMounted(true);
            setCollapsed(!collapsed);
          }}
          className="flex-center collapsed-button"
        >
          <img
            className={collapsed ? "rotate" : "open"}
            src={menuIcons.arrow}
          />
        </div>
      </div>
      {renderMenuItem("dashboard", NavigationPaths.DASHBOARD, menuIcons.dashboard)}
      {renderMenuItem("patients", NavigationPaths.PATIENTS, menuIcons.patientList)}
      {renderMenuItem("sites", NavigationPaths.SITES, menuIcons.sites)}
      {renderMenuItem("users", NavigationPaths.USERS, menuIcons.users)}
      {renderMenuItem("devices", NavigationPaths.DEVICES, menuIcons.devices)}
      {renderMenuItem("fota", NavigationPaths.FOTA, menuIcons.fota)}
      {renderMenuItem("about", NavigationPaths.ABOUT, menuIcons.about)}
      {renderMenuItem("support", NavigationPaths.SUPPORT, menuIcons.support)}
      {renderMenuItem("contact", NavigationPaths.CONTACT, menuIcons.contact)}
      {renderMenuItem("settings", NavigationPaths.SETTINGS, menuIcons.settings)}
      {hasAccess("logout") && (
        <Link onClick={logout} className="link-style" to={""}>
          <div className="item">
            <span>
              <img src={menuIcons.logout} />
            </span>
            {!collapsed && <span className="menu-text">{t("menu.logout")}</span>}
          </div>
        </Link>
      )}

      <div className="user-info-container">
        <Avatar
          initials={getFirstLettersOfFirstTwoWords(
            user?.fullName || ""
          ).toUpperCase()}
        />
        {!collapsed && (
          <div className="user-text-container">
            <span className="full-name-text">
              {user?.fullName}{" "}
              <img className="arrow-icon" src={menuIcons.arrow} />
            </span>
            <span className="user-name-text">{user?.username}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuSideBar;
