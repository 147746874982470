export enum NavigationPaths {
    DASHBOARD = "/Home/Dashboard",
    PATIENTS = "/Home/Patients",
    SITES = "/Home/Sites",
    USERS = "/Home/Users",
    DEVICES = "/Home/Devices",
    FOTA = "/Home/Fota",
    ABOUT = "/Home/About",
    SUPPORT = "/Home/Support",
    CONTACT = "/Home/Contact",
    SETTINGS = "/Home/Settings",
}