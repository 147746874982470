import React, { FC, FunctionComponent } from 'react'
import "../../styles/homePage/table/tableHeaderCards.scss"
import { useTranslation } from 'react-i18next'

import { ReactComponent as Arrow } from '../../common/assats/homeScreens/cardsIcons/arrow.svg';
import { TableCard } from '../../common/types/tableCardType';
import { TableHeaderParams } from './TableHeader';



export interface CardsProps {
    cards: TableCard[]
}
const TableHeaderCards: FC<CardsProps> = ({ cards }) => {
    const { t } = useTranslation()

    return (

        <div className='cards-main-container row'>
            {cards.map((val, index) =>
                <div key={`${val.headline}${index}`} className={`card-container col-4`}>
                    <div className='card-headline-container'>
                        <div className='card-headline-texts'>
                            <p className="card-headline-text">{val.headline}</p>
                            <p className="card-headline-explain">{val.explanation}</p>
                        </div>
                        {<val.icon />}
                    </div>
                    <div className='card-value'>
                        <span className='card-number'>{val.value}</span>
                        <span onClick={val.action} className='card-view-all'> {t(`patientsList.viewAll`)} <Arrow /></span>
                    </div>
                </div>
            )}
        </div>
    )
}

export default TableHeaderCards