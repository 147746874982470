const logos = {
    halfScreenLogo: require("./logos/halfScreenLogo.png"),
    coraLogo: require("./logos/coraLogo.png"),
}

const homeScreenImages = {
    coraLogo: require("./homeScreens/logo.png"),

}

const menuIcons = {
    patientList: require("./menu/patientsList.png"),
    about: require("./menu/about.png"),
    support: require("./menu/support.png"),
    settings: require("./menu/settings.png"),
    logout: require("./menu/logout.png"),
    arrow: require("./menu/arrow.png"),
    dashboard: require("./menu/dashboard.png"),
    devices: require("./menu/devices.png"),
    sites: require("./menu/sites.png"),
    fota: require("./menu/fota.png"),
    contact: require("./menu/contact.png"),
    users: require("./menu/usersIcon.png")
}

export {
    logos,
    homeScreenImages,
    menuIcons
}