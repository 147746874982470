import React, { useCallback, useEffect, useMemo, useState } from "react";
import "../../styles/components/SitesPage.scss";
import "../../styles/components/General.scss";
import TablePage from "../genericTable/TablePage";
import { useTranslation } from "react-i18next";
import { TableCard } from "../../common/types/tableCardType";
import { ReactComponent as TotalExamIcon } from "../../common/assats/homeScreens/cardsIcons/beforeExam.svg";
import { ReactComponent as ActiveSitesIcon } from "../../common/assats/homeScreens/cardsIcons/valid.svg";
import { ReactComponent as NonActiveSitesIcon } from "../../common/assats/homeScreens/cardsIcons/cross.svg";
import { useQuery } from "@tanstack/react-query";
import { getSitesData } from "../../common/api/apiCalls";
import { Outlet, useNavigate } from "react-router-dom";
import Filter from "../common/Filter/Filter";
import { deleteSite } from "../../common/api/apiCalls";
import { useToast } from "../../common/contexts/useToast";
import { debounce } from "../../common/function/debounce";
import { sysConfig } from "../../config";
import { siteInfoFields } from "./SiteInfoFields";
import { addressFormat } from "../../common/function/addressFormat";
import { useFilters } from "../../common/contexts/FiltersContext";

type SortOptions =
  | "siteName"
  | "superUser"
  | "siteStatus"
  | "device_sn"
  | "totalExams";

const sortKeysArray: SortOptions[] = [
  "siteName",
  "superUser",
  "siteStatus",
  "device_sn",
  "totalExams",
];

const SitesPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const [debouncedSearch, setDebouncedSearch] = useState<string>("");
  const [sortKey, setSortKey] = useState<string>("siteName");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [siteCount, setSiteCount] = useState(0);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const { showToast } = useToast();
  const { activeFilters, handleFilters, clearFilters, handleCardFilters } = useFilters();
  const {
    data: response,
    isPending,
    error,
    refetch,
  } = useQuery({
    queryKey: ["siteData", sortKey, sortOrder, activeFilters, debouncedSearch],
    queryFn: () =>
      getSitesData(sortKey, sortOrder, activeFilters, debouncedSearch),
  });

  const cards: TableCard[] = [
    {
      headline: t("sitePage.cards.activeSites"),
      explanation: t("sitePage.cards.activeSitesExplain"),
      value: response?.activeSites || 0,
      icon: ActiveSitesIcon,
      action: () => {
        handleCardFilters("siteStatus", "Active");
      },
    },
    {
      headline: t("sitePage.cards.nonActiveSites"),
      explanation: t("sitePage.cards.nonActiveSitesExplain"),
      value: response?.nonActiveSites || 0,
      icon: NonActiveSitesIcon,
      action: () => {
        handleCardFilters("siteStatus", "Inactive");

      },
    },
    {
      headline: t("sitePage.cards.totalExams"),
      explanation: t("sitePage.cards.totalExamsExplain"),
      value: response?.totalExams || 0,
      icon: TotalExamIcon,
      action: () => {
        console.log("totalExams not implemented yes");
      },
    },
  ];


  useEffect(() => {
    if (response?.data?.length && response?.total) {
      setSiteCount(response?.total);
    }
  }, [response?.length, response?.total]);


  const navigateToCreateSite = () => {
    navigate("/Home/Sites/Create");
  };

  const toggleEditSite = (id: string) => {
    const editData = response.data?.find((site: any) => site.id === id);
    const displayData = {
      siteName: editData.siteName,
      siteNumber: editData.siteNumber,
      superUserFullName: editData.siteAdmin?.fullName,
      superUserEmail: editData.siteAdmin?.username,
      superUserPhone: editData.siteAdmin?.userInfo?.phoneNumber,
      siteStatus: editData.siteStatus,
      metrics: editData.metrics,
      address: addressFormat(editData),
    };
    navigate(`/Home/Sites/View/${id}`, {
      state: {
        displayData: siteInfoFields(displayData),
        editState: editData,
        informationData: {
          title: editData.siteName,
          status: editData.siteStatus,
          pathToEdit: `/Home/Sites/Edit/${id}`,
        },
      },
    });
  };

  const debouncedSetSearch = useCallback(
    debounce((value: string) => {
      setDebouncedSearch(value);
    }, sysConfig.searchDebounce),
    []
  );

  const handleSearchChange = (str: string) => {
    setSearch(str);
    debouncedSetSearch(str);
  };

  const deleteSelectedSites = async () => {
    if (!selectedRows.length) {
      return;
    }
    const ids = selectedRows.map((row) => row.tableProps.id);
    const res = await deleteSite(ids);
    if (res.success) {
      showToast(
        "success",
        t("general.delete.title"),
        t("general.delete.success")
      );
      await refetch();
    } else {
      showToast(
        "danger",
        t("general.delete.title"),
        t(res?.data?.errorMessage || "general.delete.error")
      );
    }
  };

  const tableData = useMemo(() => {
    if (!response?.data || !response?.data?.length) return [];
    const mapData = response.data.map((site: any) => ({
      siteName: site.siteName,
      superUser: site.siteAdmin?.fullName,
      siteStatus: site?.siteStatus || "Not-active",
      device_sn: site?.device_sn || "N/A",
      totalExams: site?.totalExams || 0,
      tableProps: {
        id: site.id,
        selected: false,
        additionalData: {
          address: site.address,
          city: site.city,
          state: site.state,
          country: site.country,
          zipCode: site.zipCode,
          siteNumber: site.siteNumber,
          metrics: site.metrics,
        },
      },
    }));
    return mapData;
  }, [response, search, refetch]);

  const handleCheckBoxClick = (selectedRows: any) => {
    setSelectedRows(selectedRows);
  };

  const filtersObject = {
    siteStatus: {
      label: t("sitePage.filters.siteStatus"),
      options: [
        { name: t("sitePage.filters.active"), label: "Active" },
        { name: t("sitePage.filters.inactive"), label: "Inactive" },
      ],
    },
  };

  return (
    <div className="table-page-main-container">
      <Filter
        isOpen={isFilterOpen}
        filtersObject={filtersObject}
        handleFilterClose={() => {
          setIsFilterOpen(false);
        }}
      />
      {
        <TablePage
          viewAllAction={clearFilters}
          tableHeadline={t(`sitePage.sitesList`)}
          headerHeadline={t("sitePage.sites")}
          totalCount={siteCount}
          cards={cards}
          sortKeysArray={
            sortKeysArray?.map((key) => {
              return { name: t(`sitePage.sort.${key}`), val: key };
            }) || []
          }
          setSortKey={setSortKey}
          sortKey={sortKey}
          tableData={tableData}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
          searchValue={search}
          setSearchValue={handleSearchChange}
          searchPlaceholder={t("sitePage.siteSearch")}
          trashAction={() => {
            deleteSelectedSites();
          }}
          onCheckBoxClick={handleCheckBoxClick}
          filterAction={() => {
            setIsFilterOpen(!isFilterOpen);
          }}
          buttonText={t("sitePage.addSite")}
          buttonAction={navigateToCreateSite}
          onRowClick={(row) => {
            toggleEditSite(row.tableProps.id);
          }}
        />
      }
    </div>
  );
};

export default SitesPage;
