import React, { FC } from 'react'
import { Col, Form, Button, Row } from 'react-bootstrap';
import '../styles/loginStyles/success.scss';

import { Link, useNavigate, useParams } from 'react-router-dom';
import HalfScreenLogo from './HalfScreenLogo';
import i18n from "../i18n/i18n"
import { useTranslation } from 'react-i18next';

// interface SuccessProps {
//     text: string
//     buttonText: string
//     action: () => {}
// }
const Success = () => {
    const { type } = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const onSubmit = (event: any) => {
        event.preventDefault();

        // Handle form submission logic here
        const formData = new FormData(event.target);
        const email = formData.get('email');
        const password = formData.get('password');

        // Example: Log email and password to the console
        type === "registration" ?
            navigate("/", { replace: true, })
            :
            navigate("/Register/PersonalInfo", { replace: true, })
        // You can add more logic here, like making an API call to sign in
    };

    function getText(str: string) {
        return t(`Success.${type}.${str}`)
    }
    return (
        <HalfScreenLogo>
            <Col className="right-part d-flex align-items-center justify-content-center">
                <Form className=" form-container" onSubmit={onSubmit}>
                    <Form.Group className='title-group' style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label className="text-center global-big-label text-1o">{getText("SIGN_IN_TEXT_MAIN_TITLE")}</Form.Label>

                        {type === "registration" ?
                            <Row className='flex-center-column' >
                                <Form.Label className='text-center global-sub-label text-3o'>{getText("SIGN_IN_TEXT_SUB_TITLE_1")}</Form.Label>
                                <Form.Label className='text-center global-sub-label text-3o'>{getText("SIGN_IN_TEXT_SUB_TITLE_2")}</Form.Label>
                            </Row>
                            :
                            <Form.Label className="text-center global-big-label text-1o">{getText("SIGN_IN_TEXT_MAIN_TITLE_1")}</Form.Label>

                        }
                    </Form.Group>


                    <Button className='global-button group-spacing' variant="primary" type="submit">
                        {getText("SIGN_IN_TEXT_BUTTON_1")}
                    </Button>

                </Form>
            </Col>
        </HalfScreenLogo>
    );
}

export default Success