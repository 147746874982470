import React, { useEffect } from "react";
import "../../../styles/components/TextInput.scss";
import { useTranslation } from "react-i18next";

interface CustomNumberInputProps {
  value: number;
  name: string;
  min?: number;
  max?: number;
  step?: number;
  required?: boolean;
  showErrors?: boolean;
  handleInputChange: React.ChangeEventHandler<HTMLInputElement>;
  handleFocus?: React.FocusEventHandler<HTMLInputElement>;
  handleBlur?: (name: string) => void;
  translateErrors?: boolean;
  withPlaceHolder?: boolean;
  label?: string;
  error?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
}

const CustomNumberInput: React.FC<CustomNumberInputProps> = ({
  value,
  name,
  min,
  max,
  step = 1,
  required,
  showErrors,
  handleBlur,
  handleFocus,
  handleInputChange,
  label,
  translateErrors,
  withPlaceHolder = true,
  error,
  icon,
  disabled,
}) => {
  const { t } = useTranslation();
  const setLabel = value;
  useEffect(() => {}, [value]);

  return (
    <div
      className={`input-group custom-text-input ${
        setLabel ? "set-label" : ""
      } position-relative ${error && showErrors ? "invalid-field" : ""}`}
    >
      {withPlaceHolder && label && (
        <div className={`custom-input-label`}>
          {label}
          {required && <div className="required-asterisk">*</div>}
        </div>
      )}
      {icon && <span className="input-icon">{icon}</span>}
      <input
        type="number"
        onBlur={() => handleBlur && handleBlur(name)}
        onFocus={handleFocus}
        id={name}
        name={name}
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={handleInputChange}
        className={`form-control custom-input ${showErrors && error ? "is-invalid" : ""}`}
        disabled={disabled}
      />
      {showErrors && error && (
        <div className="invalid-feedback">
          {translateErrors ? t(error) : error}
        </div>
      )}
    </div>
  );
};

export default CustomNumberInput;
