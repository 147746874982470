import React, { useEffect } from "react";
import { Toast } from "react-bootstrap";
import { ToastContainerProps } from "../../../types/Toast.interface";
import "../../../styles/components/Toast.scss";

const ToastContainer: React.FC<ToastContainerProps> = ({
  toasts,
  removeToast,
}) => {

  useEffect(() => {
    const timers = toasts.map((toast) =>
      setTimeout(() => {
        removeToast(toast.id);
      }, toast.duration || 5000) 
    );

    return () => {
      timers.forEach((timer) => clearTimeout(timer));
    };
  }, [toasts, removeToast]);

  return (
    <div
      className="toast-container position-fixed top-0 end-0 p-3"
      style={{ zIndex: 1055 }}
    >
      {toasts.map((toast) => (
        <div
          key={toast.id}
          className={`toast show align-items-center text-bg-${toast.type || "info"} border-0 toast-custom-container`}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          {/* TODO: add icons */}
          <div className="d-flex">
            <div className="toast-body">
              <strong>{toast.title}</strong>
              <div>{toast.message}</div>
            </div>
            <button
              type="button"
              className="btn-close me-2 m-auto"
              aria-label="Close"
              onClick={() => removeToast(toast.id)}
            ></button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ToastContainer;
