import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../styles/components/DatePicker.scss"

interface CustomDatePickerProps {
  name: string;
  label?: string;
  value: Date | null;
  handleFocus: (event: React.FocusEvent<HTMLInputElement>) => void; 
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleInputChange: ({ target: { name, value } }: { target: { name: string; value: Date | null } }) => void;

  required?: boolean;
  error?: string;
  showErrors?: boolean;
  disabled?: boolean;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  name,
  label,
  value,
  handleFocus,
  handleInputChange,
  handleBlur,
  required,
  error,
  showErrors,
  disabled,
}) => {

  const createCustomEvent = (value: Date | null) => {
    handleInputChange({ target: { name: name, value: value } });

  }
  

  return (
    <div className="custom-date-picker-container">
      <div className="custom-date-picker">
      {label && (
        <label
          htmlFor={name}
          className={`custom-date-picker-label ${
            required ? "required-label" : ""
          }`}
        >
          {label}
          {required && <span className="required-asterisk">*</span>}
        </label>
      )}

      <DatePicker
        selected={value}
        onChange={(date) => createCustomEvent(date)}
        dateFormat="MMM d, yyyy"
        className={`form-control ${
          showErrors && error ? "is-invalid" : ""
        }`}
        onFocus={handleFocus}
        placeholderText="Pick a Date"
        name={name}
        onBlur={handleBlur}
        id={name}
        monthsShown={2}
        autoComplete="off"
        disabled={disabled}
      />
      </div>
     

      {showErrors && error && (
        <div className="invalid-feedback">{error}</div>
      )}
    </div>
  );
};

export default CustomDatePicker;
