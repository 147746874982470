import React, { FunctionComponent } from "react";
import "../../../styles/components/DynamicModal.scss";
import { ReactComponent as CloseIcon } from "../../../common/assats/icons/closeXIcon.svg";

interface DynamicModalProps {
  title: string;
  icon: React.ReactNode; 
  subtext: string;
  buttonText: string;
  onButtonClick: () => void; // Button click handler
  onClose: () => void; // Close button handler
}

const DynamicModal: React.FC<DynamicModalProps> = ({
  title,
  icon,
  subtext,
  buttonText,
  onButtonClick,
  onClose,
}) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <div className="modal-icon">
           {icon}
          
          </div>
          <button className="modal-close" onClick={onClose}>
            <CloseIcon />
          </button>
        </div>

        <div className="modal-body">
          <h2>{title}</h2>
          <p>{subtext}</p>
        </div>
        <div className="modal-footer">
          <button className="modal-button" onClick={onButtonClick}>
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DynamicModal;
