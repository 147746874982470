import * as Yup from "yup";
import { SiteStepsEnum } from "../enums/generalEnum";


export const siteValidationSchemas: Record<SiteStepsEnum, Yup.ObjectSchema<any>> = {
  [SiteStepsEnum.SITE_INFORMATION]: Yup.object().shape({
    siteName: Yup.string().required("sitePage.siteCreate.formErrors.siteNameRequired"),
    siteNumber: Yup.string().required("sitePage.siteCreate.formErrors.siteNumberRequired"),
    superUserFullName: Yup.string().required("sitePage.siteCreate.formErrors.superUserFullNameRequired"),
    superUserEmail: Yup.string()
      .email("sitePage.siteCreate.formErrors.superUserEmailNotValid")
      .required("sitePage.siteCreate.formErrors.superUserEmailRequired"),
    superUserPhone: Yup.string()
      .matches(/^\d{10}$/, "sitePage.siteCreate.formErrors.superUserPhoneNotValid")
      .required("sitePage.siteCreate.formErrors.superUserPhoneRequired"),
    siteStatus: Yup.string(), //.required("sitePage.siteCreate.formErrors.siteStatusRequired"),
    metrics: Yup.string(), //.required("sitePage.siteCreate.formErrors.siteMetricsRequired")
  }),
  
  [SiteStepsEnum.SITE_ADDRESS]: Yup.object().shape({
    address: Yup.string().required("sitePage.siteCreate.formErrors.siteAddressRequired"), //Add validation for format
    city: Yup.string().required("sitePage.siteCreate.formErrors.siteCityRequired"),
    state: Yup.string(),
    // .required("sitePage.siteCreate.formErrors.stateRequired")
    zip: Yup.string(),
      // .matches(/^\d{5}$/, "sitePage.siteCreate.formErrors.siteZipCodeNotValid"),
      // .required("sitePage.siteCreate.formErrors.siteZipCodeRequired"),
    country: Yup.string(),
    // .required("sitePage.siteCreate.formErrors.siteCountryRequired")
  }),
};
