import React from 'react'
import { Col, Form, Button } from 'react-bootstrap';
import '../../styles/loginStyles/setNewPassword.scss';

import { Link } from 'react-router-dom';
import HalfScreenLogo from '../../components/HalfScreenLogo';
import i18n from "../../i18n/i18n"
const SetNewPassword = () => {
    const SIGN_IN_TEXT_MAIN_TITLE = i18n.t('ResetPassword.SIGN_IN_TEXT_MAIN_TITLE');
    const SIGN_IN_TEXT_SUB_TITLE = i18n.t('ResetPassword.SIGN_IN_TEXT_SUB_TITLE');
    const SIGN_IN_TEXT_INPUT_1_PLACEHOLDER = i18n.t('ResetPassword.SIGN_IN_TEXT_INPUT_1_PLACEHOLDER');
    const SIGN_IN_TEXT_INPUT_2_PLACEHOLDER = i18n.t('ResetPassword.SIGN_IN_TEXT_INPUT_2_PLACEHOLDER');
    const SIGN_IN_TEXT_BUTTON_1 = i18n.t('ResetPassword.SIGN_IN_TEXT_BUTTON_1');
    const SIGN_IN_TEXT_BUTTON_2 = i18n.t('ResetPassword.SIGN_IN_TEXT_BUTTON_2');
    const SIGN_IN_TEXT_LABEL_1 = i18n.t('ResetPassword.SIGN_IN_TEXT_LABEL_1');

    const onSubmit = (event: any) => {
        event.preventDefault();

        // Handle form submission logic here
        const formData = new FormData(event.target);
        const email = formData.get('email');
        const password = formData.get('password');

        // Example: Log email and password to the console

        // You can add more logic here, like making an API call to sign in
    };

    return (
        <HalfScreenLogo>
            <Col className="right-part d-flex align-items-center justify-content-center">
                <Form className="form-container" onSubmit={onSubmit}>
                    <Form.Group className='title-group' style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label className="global-big-label text-1o">{SIGN_IN_TEXT_MAIN_TITLE}</Form.Label>
                    </Form.Group>
                    <Form.Group className='group-spacing' controlId="formBasicEmail">
                        <Form.Control className='global-input' type="password" placeholder={SIGN_IN_TEXT_INPUT_1_PLACEHOLDER} />
                    </Form.Group>
                    <Form.Group className='group-spacing' controlId="formBasicPassword">
                        <Form.Control className='global-input' type="password" placeholder={SIGN_IN_TEXT_INPUT_2_PLACEHOLDER} />
                    </Form.Group>


                    <Button className='global-button group-spacing' variant="primary" type="submit">
                        {SIGN_IN_TEXT_BUTTON_1}
                    </Button>

                </Form>
            </Col>
        </HalfScreenLogo>
    );
}

export default SetNewPassword