import { useState } from "react";
import * as Yup from "yup";
import {
  ClinicalInfoInterface,
  DemographicInfoInterface,
} from "../../types/PatientData.interface";
import patientDataTemplate from "../../components/patientsList/addPatient/patientDataTemplate";

interface UseFormValidationProps<T> {
  formValues: T;
  validationSchema: Yup.ObjectSchema<any>;
}

const useFormValidation = <T extends Record<string, any>>({
  formValues,
  validationSchema,
}: UseFormValidationProps<T>) => {
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});

  const cleanErrorObject = () => {
    return Object.keys(formValues).reduce((acc, key) => {
      acc[key as keyof T] = null; // Set initial value to null
      return acc;
    }, {} as { [K in keyof T]: null | string })
  }

  const [errors, setErrors] = useState<{
    [K in keyof T]: null | string;
  }>(
   cleanErrorObject()
  );

  // Validation function
  const checkForErrors = async (
    params:
    {
      checkAll?: boolean,
      name?: keyof (ClinicalInfoInterface & DemographicInfoInterface)
    }
  ): Promise<boolean> => {
    try {
      const touchedObject = (touched: boolean) => {
        return Object.keys(patientDataTemplate).reduce((acc, key) => {
          acc[key as keyof (ClinicalInfoInterface & DemographicInfoInterface)] =
            touched;
          return acc;
        }, {} as Record<keyof (ClinicalInfoInterface & DemographicInfoInterface), boolean>);
      };
      if(params.checkAll){
        setTouched(touchedObject(true));
      }
      if (params.name) {
       
        errors[params.name] &&
          errors[params.name] !== null &&
          (
            await validationSchema.validateAt(
              params.name, formValues
          )
        );
        setErrors(prevErrors => ({
          ...prevErrors, // Spread previous errors
          [params.name as string]: null // Set the specific field's error to null
        }));
      } else {
        await validationSchema.validate(formValues, {
          abortEarly: false,
        });
        setErrors(Object.keys(formValues).reduce((acc, key) => {
          acc[key as keyof T] = null; // Set initial value to null
          return acc;
        }, {} as { [K in keyof T]: null | string })); // Clear errors if valid
      }
      if(params.checkAll){
        setTouched(touchedObject(false));
      }
      return false; // No errors
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        // Create validation errors only for touched fields
        const validationErrors = err.inner.reduce(
          (acc: any, currentError: any) => {
            // Check if the field is touched and has a true value
            if (touched[currentError.path] || params.checkAll) {
              acc[currentError.path] = currentError.message; // Add error message
            }
            return acc; // Return accumulator for next iteration
          },
          {}
        );

        setErrors(params.name ? {...errors, validationErrors} : validationErrors); // Set the errors
        return Object.keys(params.name ? {...errors, validationErrors} : validationErrors).length > 0; // Return true if there are any errors found
      }
    }
    return false;
  };

  return {
    checkForErrors,
    errors,
    touched,
    setTouched,
  };
};

export default useFormValidation;
