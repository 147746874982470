import { UsersPropsGeneralInformation } from "../../common/interfaces/usersData.interface";
import { InfoFieldsBuilderInterface } from "../common/InfoFieldsBuilder";

export const userInfoFields = (
  data: UsersPropsGeneralInformation
): InfoFieldsBuilderInterface[] => [
    {
        title: "usersList.sort.position",
        textArray: [data?.position ?? ""],
        colWidth: "col-sm-3",
        translate: true,
    },
    {
        title: "usersList.sort.role",
        textArray: [data?.role ?? ""],
        colWidth: "col-sm-3",
        translate: true,
    },
    {
        title: "usersList.sort.username",
        textArray: [data?.email ?? ""],
        colWidth: "col-sm-3",
        translate: true,
    },
    {
        title: "usersList.sort.phoneNumber",
        textArray: [data?.phoneNumber ?? ""],
        colWidth: "col-sm-3",
        translate: true,
    },
    {
        title: "usersList.sort.relatedSite",
        textArray: [data?.relatedSite ?? ""],
        colWidth: "col-sm-3",
        translate: true,
    }
];
