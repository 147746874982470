export const getErrorMessage = (error: any): string => {
    let errorMessage = "general.errors.generalError";
    if (!error) {
        return errorMessage
    }

    // Check if the error has a response and an error code
    if (error.response) {
        const { status, data } = error.response;
        const errorCode = data?.error || "UNKNOWN_ERROR";

        // Map error codes to user-friendly messages (i18n)
        const errorMessages: Record<string, string> = {
            // General errors
            "GE1": "general.errors.generalError",

            // Site creation errors
            "SN1": "sitePage.siteCreate.errors.siteNameExists",
            "SN2": "sitePage.siteCreate.errors.sitesFetchError",
            // user section
            "U1": "usersList.errors.userNotFound",
            "U2": "usersList.errors.userEmailExists",
            "U3": "usersList.errors.couldNotCreateUser",
            "U4": "usersList.errors.userPersonalIdExists",

            // Roles section
            "R1": "sitePage.siteCreate.errors.rolesFetchError",
            "R2": "sitePage.siteCreate.errors.roleNotFound",

            // Patient section
            "P1": "patientsList.errors.patientNotFound",
            "P2": "patientsList.errors.patientEmailExists",
            "P3": "patientsList.errors.couldNotCreatePatient",
            "P4": "patientsList.errors.patientPersonalIdExists",

        };

        errorMessage = errorMessages[errorCode] || errorMessage;
    }

    return errorMessage;
};