import React, { SetStateAction, useState } from "react";
// import DemographicsQuestions, {
//   FieldConfig,
// } from "../../common/DemographicsQuestions";

import {
  ClinicalInfoInterface,
  DemographicInfoInterface,
} from "../../../types/PatientData.interface";
import FormBuilder, {
  CustomDateChangeEvent,
  FormBuilderInterface,
} from "../../common/FormBuilder";
import { AddPatientFieldConfig } from "./AddPatientFieldConfigurations";
import dayjs from "dayjs";

interface AddPatientFormProps {
  currentStep: number;
  setPatientData: (values: any) => void;
  errors?: { [key: string]: string | null };
  checkForErrors?: (params: {
    checkAll?: boolean;
    name?: keyof (ClinicalInfoInterface & DemographicInfoInterface);
  }) => void;
  patientData: ClinicalInfoInterface & DemographicInfoInterface;
  showErrors: boolean;
  touched: { [key: string]: boolean };
  setTouched: (state: { [key: string]: boolean }) => void;
}

interface ClinicalQuestionInterface extends FormBuilderInterface {
  name: keyof ClinicalInfoInterface;
}

const AddPatientForm = (props: AddPatientFormProps) => {
  const {
    currentStep,
    setPatientData,
    patientData,
    showErrors,
    errors,
    checkForErrors,
    touched,
    setTouched,
  } = props;

  const booleanDisplay = (value: string): boolean | string => {
    return value === "Yes" ? true : value === "No" ? false : value;
  };

  const handleUpdatePatientData = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
      | CustomDateChangeEvent
  ) => {
    const { name, value } = event.target;

    // Check if the value is a Date instance and format it using dayjs
    const formattedValue =
      value instanceof Date ? dayjs(value).format("YYYY-MM-DD") : value || "";
      setTouched({
        ...touched,
        [name]: true,
      });
    setPatientData((prevValues: any) => ({
      ...prevValues,
      [name]: booleanDisplay(formattedValue), // Use booleanDisplay for final value handling
    }));
   
  };

  const chestPainQuestions: ClinicalQuestionInterface[] = [
    {
      name: "chestPainType",
      question: "Type of chest pain",
      optionsArray: ["Typical", "Atypical"],
      type: "radio",
      nested: 1,
      fullRow: true,

    },
  ];

  const conditionalQuestions: ClinicalQuestionInterface[] = [
    {
      name: "symptomsType",
      question: "Type of symptoms",
      optionsArray: ["Acute", "Chronic"],
      type: "radio",
      fullRow: true,
    },
    {
      name: "chestPain",
      question: "Chest pain",
      optionsArray: ["Yes", "No"],
      type: "radio",
      fullRow: true,

    },
    ...(patientData.chestPain ? chestPainQuestions : []),

    {
      name: "shortnessOfBreath",
      question: "Shortness of breath",
      optionsArray: ["Yes", "No"],
      type: "radio",
      fullRow: true,
    },
    {
      name: "shoulderOrArmPain",
      question: "Shoulder or arm pain",
      optionsArray: ["Yes", "No"],
      type: "radio",
      fullRow: true,
    },
    {
      name: "jawPain",
      question: "Jaw pain",
      optionsArray: ["Yes", "No"],
      type: "radio",
      fullRow: true,
    },
    {
      name: "dizzinessOrPresyncope",
      question: "Dizziness/pre-syncope",
      optionsArray: ["Yes", "No"],
      type: "radio",
      fullRow: true,
    },
    {
      name: "nausea",
      question: "Nausea",
      optionsArray: ["Yes", "No"],
      type: "radio",
      fullRow: true,
    },
  ];

  const firstClinical: ClinicalQuestionInterface[] = [
    {
      name: "isSymptomatic",
      question: "Is the patient symptomatic?",
      optionsArray: ["Yes", "No"],
      type: "radio",
      fullRow: true,
    },
    ...(patientData.isSymptomatic ? conditionalQuestions : []),
  ];

  const fieldConfigArray = [
    AddPatientFieldConfig.DEMOGRAPHICS,
    AddPatientFieldConfig.ADDRESS,
    firstClinical,
    AddPatientFieldConfig.CLINICAL_TWO,
    AddPatientFieldConfig.CLINICAL_THREE,
    AddPatientFieldConfig.CLINICAL_FOUR,
  ];

  return (
    <div className="patient-form-container">
      <FormBuilder
        errors={errors}
        values={patientData}
        fields={fieldConfigArray[currentStep]}
        handleInputChange={(
          e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
            | CustomDateChangeEvent
        ) => {
      
          checkForErrors?.({
            checkAll: false,
            name: e.target.name as keyof (ClinicalInfoInterface &
              DemographicInfoInterface),
          });
          handleUpdatePatientData(e);
        }}
        showErrors={showErrors}
        handleBlur={(name?: keyof ClinicalInfoInterface | keyof DemographicInfoInterface) => {
          checkForErrors?.({checkAll: false})}
        }
      />
    </div>
  );
};

export default AddPatientForm;
