import { HonorificEnum } from "../../../common/enums/PatientEnums";
import { ClinicalInfoInterface, DemographicInfoInterface } from "../../../types/PatientData.interface";
import { FormBuilderInterface } from "../../common/FormBuilder";

export interface AddPatientFieldConfigInterface extends FormBuilderInterface {
  name: keyof (ClinicalInfoInterface & DemographicInfoInterface);
}


export const demographicsAddPatientFieldConfig: AddPatientFieldConfigInterface[] = [
  {
    name: "honorific",
    type: "dropdown",
    label: "Honorific",
    optionsArray: Object.values(HonorificEnum),
    required: true,
  },
  {
    name: "gender",
    type: "dropdown",
    label: "Gender",
    optionsArray: ["Male", "Female", "Other"],
    required: true,
  },
  { name: "birthday", type: "date", label: "Date of Birth", required: true },
  { name: "firstName", type: "text", label: "First Name", required: true },
  { name: "middleName", type: "text", label: "Middle Name" },
  { name: "lastName", type: "text", label: "Last Name", required: true },
  { name: "email", type: "text", label: "Email", required: true },
  { name: "phoneNumber", type: "text", label: "Phone Number", required: true },
  {
    name: "maritalStatus",
    type: "dropdown",
    label: "Marital Status",
    optionsArray: ["Single", "Married", "Divorced", "Widowed"],
    offset: "4",
    translateOffset: true,
    required: true,
  },
  {
    name: "race",
    type: "text",
    label: "Race/Ethnicity",
    translateOffset: true,
    required: true,
  },
  {
    name: "medId",
    type: "number",
    label: "Medical ID",
    offset: "4",
    translateOffset: true,
    required: true
  },
  {
    name: "identityNumber",
    type: "text",
    label: "Patient ID",
    translateOffset: true,
    required: true,
  },
];

const secondClinical: AddPatientFieldConfigInterface[] = [
  {
    name: "smokingStatus",
    type: "dropdown",
    label: "Smoking",
    optionsArray: [
      "No",
      "Cessation Over 5yrs",
      "Cessation Less than 5yrs",
      "Yes (Over a pack/day)",
      "Yes (Less than a pack/day)",
    ],
  },
  {
    name: "takingOtherDrugs",
    type: "dropdown",
    label: "Prescription Medications?",
    optionsArray: ["Statins", "BP drugs", "Other (TBD)"],
  },
  {
    name: "sugarLevel",
    type: "text",
    label: "Sugar level",
    suffix: "mg/dL",
  },
  {
    name: "totalCholesterol",
    type: "text",
    label: "Total cholesterol",
    suffix: "mg/dL",
  },
  {
    name: "ldl",
    type: "text",
    label: "LDL",
    suffix: "mg/dL",
  },
  {
    name: "triglycerides",
    type: "text",
    label: "Triglycerides",
    suffix: "mg/dL",
  },
  {
    name: "bpSystolic",
    type: "text",
    label: "Systolic BP",
    suffix: "mmHg",
  },
  {
    name: "bpDiastolic",
    type: "text",
    label: "Diastolic BP",
    suffix: "mmHg",
  },
  {
    name: "height",
    type: "text",
    label: "Height",
    suffix: "cm",
    offset: "4",
    translateOffset: true,
  },
  {
    name: "weight",
    type: "text",
    label: "Weight",
    suffix: "kg",
    translateOffset: true,
  },
  {
    name: "notes",
    type: "text",
    label: "Notes",
    colWidth: "col-12",
    
  },
]
const thirdClinical:AddPatientFieldConfigInterface[] = [
  {
    name: "copd",
    question: "COPD?",
    optionsArray: ["Yes", "No"],
    type: "radio",
    fullRow: true,
  },
  {
    name: "arrhythmiasOrConductionAbnormality",
    question: "Arrhythmias or conduction abnormality?",
    optionsArray: ["Yes", "No"],
    type: "radio",
    fullRow: true,
  },
  {
    name: "peripheralArterialDisease",
    question: "Peripheral arterial disease?",
    optionsArray: ["Yes", "No"],
    type: "radio",
    fullRow: true,
  },
  {
    name: "familyHistoryCardiovascularEvents",
    question: "Family history for cardiovascular events?",
    optionsArray: ["Yes", "No"],
    type: "radio",
    fullRow: true,
  },
  {
    name: "cerebrovascularDisease",
    question: "Cerebrovascular disease?",
    optionsArray: ["Yes", "No"],
    type: "radio",
    fullRow: true,
  },
  {
    name: "strokeOrTiaOrRIND",
    question:
      "Stroke, TIA, or reversible ischemic neurological disease within the last 6 months?",
    optionsArray: ["Yes", "No"],
    type: "radio",
    fullRow: true,
  },
  {
    name: "chronicHeartFailure",
    question: "Chronic heart failure?",
    optionsArray: ["Yes", "No"],
    type: "radio",
    fullRow: true,
  },
]

const fourthClinical:AddPatientFieldConfigInterface[] = [
  {
    name: "lvefLessThan30",
    question: "LVEF <30%?",
    optionsArray: ["Yes", "No"],
    type: "radio",
    fullRow: true,
  },
  {
    name: "previousCardiacEvent",
    question: "Previous cardiac event (MI, cardiogenic shock, etc.)?",
    optionsArray: ["Yes", "No"],
    type: "radio",
    fullRow: true,
  },
  {
    name: "hemodynamicInstability",
    question: "Hemodynamic instability?",
    optionsArray: ["Yes", "No"],
    type: "radio",
    fullRow: true,
  },
  {
    name: "pulmonaryEdema",
    question: "Pulmonary edema?",
    optionsArray: ["Yes", "No"],
    type: "radio",
    fullRow: true,
  },
  {
    name: "anemia",
    question: "Anemia (Hb <10)?",
    optionsArray: ["Yes", "No"],
    type: "radio",
    fullRow: true,
  },
  {
    name: "otherCoMorbidities",
    label: "Other co-morbidities?",
    type: "text",
    colWidth: "col-12",
    fullRow: true,
  },
]

const addressFields: AddPatientFieldConfigInterface[] = [
  {
    name: "address",
    type: "text",
    label: "Address (street name and house number)",
    colWidth: '12',
    required: true
  },
  { name: "city", type: "text", label: "City" ,required: true},
  {
    name: "state",
    type: "text",
    label: "State",
  },
  { name: "zip", type: "text", label: "Zipcode", required: true },
  { name: "country", type: "text", label: "Country", required: true },
];

export const AddPatientFieldConfig = {
  DEMOGRAPHICS: demographicsAddPatientFieldConfig,
  ADDRESS: addressFields,
  CLINICAL_TWO: secondClinical,
  CLINICAL_THREE: thirdClinical,
  CLINICAL_FOUR: fourthClinical
};