import React from 'react';
import CircleGraph from './Graphs/CircleGraph';
import '../../styles/components/GraphCard.scss'
import BarGraph from './Graphs/BarGraph';
interface GraphCardProps{
    title: string;
    property: string;
    objectsArray: Record<string, any>[]
    type: 'circle' | 'bar'
    barCount?: number
}
const GraphCard = (props: GraphCardProps) => {

    const {
        title,
        type,
        objectsArray,
        property,
        barCount
    } = props

    return (
        <div className='GraphCard'>
            <div className='title'>
                {title}
            </div>
            {type === 'circle' ?  <CircleGraph objectsArray={objectsArray} property={property}/> :
            
            <BarGraph objectsArray={objectsArray} property={property} barCount={barCount}/>}
        </div>

    );
};

export default GraphCard;