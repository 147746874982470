// ThemeContext.tsx
import React, { createContext, useContext, useState, ReactNode, useEffect, useRef } from 'react';
import { User } from '../consts/user';
import Cookies from 'js-cookie';
import { getUserInfo } from '../api/apiCalls';

interface AppDataContextType {
    // setAppData: (newAppData: Partial<AppData>) => void
    setUser: React.Dispatch<React.SetStateAction<User | null | undefined>>
    user: User | null | undefined
    getUser: () => Promise<User | null | undefined>

}

// Create the context
const UserContext = createContext<AppDataContextType | undefined>(undefined);

// Create a custom provider component
interface AppDataContextProviderProps {
    children: ReactNode;
}


export const UserContextProvider: React.FC<AppDataContextProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | null | undefined>(null)
    useEffect(() => {
        getUser()

    }, [])

    async function getUser() {
        const u = await getUserInfo()
        setUser(u)
        return u
    }

    const contextValue: AppDataContextType = {
        setUser,
        user,
        getUser
    };


    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    const context = useContext(UserContext);

    if (!context) {
        throw new Error('useTheme must be used within a AppDataContextProvider');
    }

    return context;
};
