import React, { useCallback, useEffect, useMemo, useState } from "react";
import MainHome from "../../components/MainHome";
import "./../../styles/homePage/patientsList.scss";
import TablePage from "../../components/genericTable/TablePage";
import { useTranslation } from "react-i18next";
import { TableCard } from "../../common/types/tableCardType";
import { ReactComponent as ActiveSitesIcon } from "../../common/assats/homeScreens/cardsIcons/valid.svg";
import { ReactComponent as NonActiveSitesIcon } from "../../common/assats/homeScreens/cardsIcons/cross.svg";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  UserCreateInterface,
  UsersDataResponseInterface,
} from "../../common/interfaces/usersData.interface";
import {
  deleteUsers,
  getAllBranchPatients,
  getUsersData,
} from "../../common/api/apiCalls";
import Filter from "../../components/common/Filter/Filter";
import { debounce } from "../../common/function/debounce";
import { sysConfig } from "../../config";
import { useToast } from "../../common/contexts/useToast";
import { Positions } from "../../components/common/general/positions";
import { userInfoFields } from "../../components/usersList/UserInfoFields";
import { useFilters } from "../../common/contexts/FiltersContext";

type SortOptions =
  | "fullName"
  | "username"
  | "phoneNumber"
  | "status"
  | "relatedSite"
  | "position"
  | "role";

const sortKeysArray: SortOptions[] = [
  "fullName",
  "username",
  "phoneNumber",
  "status",
  "relatedSite",
  "position",
  "role",
];

const UsersList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const [debouncedSearch, setDebouncedSearch] = useState<string>("");
  const [sortKey, setSortKey] = useState<string>("username");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [usersCounts, setUsersCounts] = useState(0);
  const { activeFilters, clearFilters, handleCardFilters } = useFilters();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const { showToast } = useToast();
  const [filtersObject, setFiltersObject] = useState({
    userStatus: {
      label: t("usersList.filters.userStatus"),
      options: [
        { name: t("sitePage.filters.active"), label: "Active" },
        { name: t("sitePage.filters.inactive"), label: "Inactive" },
      ],
    },
    relatedSite: {
      label: t("usersList.filters.relatedSite"),
      options: [],
    },
    positions: {
      label: t("usersList.filters.position"),
      options: Positions.map((position) => {
        return { name: t(position), label: t(position) };
      }).slice(0,-1),
    },
    role: {
      label: t("usersList.filters.role"),
      options: [],
    },
  });

  const {
    data: response,
    isPending,
    error,
    refetch,
  } = useQuery({
    queryKey: [
      "patientsData",
      sortKey,
      sortOrder,
      activeFilters,
      debouncedSearch,
    ],
    queryFn: () =>
      getUsersData({
        sortKey,
        sortOrder,
        filters:activeFilters,
        search: debouncedSearch,
      }),
  });

  const cards: TableCard[] = [
    {
      headline: t("usersList.cards.ActiveUsers"),
      explanation: t("patientsList.cards.afterExamExplain"),
      icon: ActiveSitesIcon,
      value: response?.data?.activeUsersCount,
      action: () => {
        handleCardFilters("userStatus", "Active");
      },
    },
    {
      headline: t("usersList.cards.nonActiveUsers"),
      explanation: t("patientsList.cards.afterExamExplain"),
      icon: NonActiveSitesIcon,
      value: response?.data?.nonActiveUsersCount,
      action: () => {
        handleCardFilters("userStatus", "Inactive");
      },
    },
  ];

  useEffect(() => {
    if (response?.success && response?.data.count) {
      setUsersCounts((prev) => response.data.count);
      setFiltersObject((prevFilters) => ({
        ...prevFilters,
        relatedSite: {
          ...prevFilters.relatedSite,
          options: response?.data.sites.map((site: string) => ({
            name: site,
            label: site,
          })),
        },
        role: {
          ...prevFilters.role,
          options: response?.data.roles.map((role: string) => ({
            name: role,
            label: role,
          })),
        },
      }));
    }
  }, [response, usersCounts]);

  const filteredData = useMemo(() => {
    if (!response || !response.data?.users?.length || !response?.success)
      return [];
    const tdata = response.data.users.map(
      (user: UsersDataResponseInterface) => {
        return {
          userName: user.fullName,
          emailAddress: user.username,
          phoneNumber: user.userInfo?.phoneNumber,
          status: user?.needToChangePassword ? "Inactive" : "Active",
          relatedSite: user.site?.[0]?.siteName || "",
          position: user.userInfo?.position || "",
          role: user.roles[0].name,
          tableProps: {
            id: user.id,
            selected: false,
          },
        };
      }
    );
    return tdata;
  }, [response, search]);

  const navigateToUserDetails = (row: any) => {
    const userData = response.data.users.find(
      (user: UsersDataResponseInterface) => user.username === row.emailAddress
    );
    const displayData = {
      position: userData.userInfo?.position || "",
      role: userData.roles[0].name,
      email: userData.username,
      phoneNumber: userData.userInfo?.phoneNumber || "",
      relatedSite: userData.site?.[0]?.siteName || "",
    };
    navigate(`/Home/Users/View/${row.emailAddress}`, {
      state: {
        displayData:userInfoFields(displayData),
        editState: userData,
        informationData: {
          title: userData.fullName,
          status: userData.needToChangePassword ? "Inactive" : "Active",
          pathToEdit: `/Home/Users/Edit/${userData.username}`,
        }
      },
    });
  };


  const navigateToAddUser = () => {
    navigate("/Home/Users/create");
  };

  const debouncedSetSearch = useCallback(
    debounce((value: string) => {
      setDebouncedSearch(value);
    }, sysConfig.searchDebounce),
    []
  );

  const handleSearchChange = (str: string) => {
    setSearch(str);
    debouncedSetSearch(str);
  };

  const handleCheckBoxClick = (selectedRows: any) => {
    setSelectedRows(selectedRows);
  };

  const deleteSelectedSites = async () => {
    if (!selectedRows.length) {
      return;
    }
    const ids = selectedRows.map((row) => row.tableProps.id);
    const res = await deleteUsers(ids);
    if (res.success) {
      showToast(
        "success",
        t("general.delete.title"),
        t("general.delete.success")
      );
      await refetch();
    } else {
      showToast(
        "danger",
        t("general.delete.title"),
        t(res?.data?.errorMessage || "general.delete.error")
      );
    }
  };

  return (
    <MainHome>
      <Filter
        isOpen={isFilterOpen}
        filtersObject={filtersObject}
        handleFilterClose={() => {
          setIsFilterOpen(false);
        }}
      />
      <TablePage
        tableHeadline={t(`usersList.usersList`)}
        headerHeadline={t("usersList.userStatus")}
        cards={cards}
        totalCount={usersCounts}
        viewAllAction={clearFilters}
        filterAction={() => {
          setIsFilterOpen(!isFilterOpen);
        }}
        searchValue={search}
        setSearchValue={handleSearchChange}
        searchPlaceholder={t("patientsList.Search_by_Name_or_ID")}
        buttonText={t("usersList.addUser")}
        buttonAction={navigateToAddUser}
        setSortKey={setSortKey}
        sortKey={sortKey}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        tableData={filteredData}
        trashAction={() => {
          deleteSelectedSites();
        }}
        onCheckBoxClick={handleCheckBoxClick}
        sortKeysArray={
          sortKeysArray
            ? sortKeysArray.map((value) => {
                return { name: t(`usersList.sort.${value}`), val: value };
              })
            : []
        }
        onRowClick={(row: UsersDataResponseInterface) => {
          navigateToUserDetails(row);
        }}
      />
    </MainHome>
  );
};

export default UsersList;
