import React, { useState } from "react";
import { Col, Form, Button } from "react-bootstrap";
import "../../styles/loginStyles/signIn.scss";
import { Link, Navigate, useParams } from "react-router-dom";
import HalfScreenLogo from "../../components/HalfScreenLogo";
import i18n from "../../i18n/i18n";
import { useNavigate } from "react-router-dom";
import { postLogin } from "../../common/api/apiCalls";
import { FormErrors } from "../../common/types/formErrorType";

const SIGN_IN_TEXT_MAIN_TITLE = i18n.t("sign_in.SIGN_IN_TEXT_MAIN_TITLE");
const SIGN_IN_TEXT_SUB_TITLE = i18n.t("sign_in.SIGN_IN_TEXT_SUB_TITLE");
const SIGN_IN_TEXT_INPUT_1_PLACEHOLDER = i18n.t("sign_in.SIGN_IN_TEXT_INPUT_1_PLACEHOLDER");
const SIGN_IN_TEXT_INPUT_2_PLACEHOLDER = i18n.t("sign_in.SIGN_IN_TEXT_INPUT_2_PLACEHOLDER");
const SIGN_IN_TEXT_BUTTON_1 = i18n.t("sign_in.SIGN_IN_TEXT_BUTTON_1");



function SignIn() {
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [formData, setFormData] = useState({ email: '', password: '' });
  const { token } = useParams();

  if (!token) return <Navigate to={"/NotAuthorized"} />;

  const validateForm = () => {
    const errors: FormErrors = {};
    if (!formData.email) errors.email = 'Email field is required';
    if (!formData.password) errors.password = 'Password field is required';
    return errors;
  };

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    
    // Validate form fields
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // Call API
    const res = await postLogin({
      username: formData.email,
      password: formData.password,
    });

    if (res.success) {
      navigate("/Register/SetNewPassword", { replace: true });
    } else if (res.error === 'User does not exist' || res.error === 'Wrong password') {
      setFormErrors({ password: 'Incorrect username or password' });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  return (
    <HalfScreenLogo>
      <Col className="right-part d-flex align-items-center justify-content-center">
        <Form className="form-container" onSubmit={onSubmit}>
          <Form.Group className="title-group" style={{ display: "flex", flexDirection: "column" }}>
            <Form.Label className="global-big-label text-1o">{SIGN_IN_TEXT_MAIN_TITLE}</Form.Label>
            <Form.Label className="global-sub-label text-3o">{SIGN_IN_TEXT_SUB_TITLE}</Form.Label>
          </Form.Group>

          <Form.Group className="group-spacing" controlId="formBasicEmail">
            <Form.Control
              className={`global-input ${formErrors.email ? "is-invalid" : ""}`}
              type="email"
              name="email"
              placeholder={SIGN_IN_TEXT_INPUT_1_PLACEHOLDER}
              value={formData.email}
              onChange={handleChange}
            />
          
              <Form.Text className="text-danger" style={{ marginLeft: "20px" }}>
                {formErrors.email}
              </Form.Text>
        
          </Form.Group>

          <Form.Group className="group-spacing" controlId="formBasicPassword">
            <Form.Control
              className={`global-input ${formErrors.password ? "is-invalid" : ""}`}
              type="password"
              name="password"
              placeholder={SIGN_IN_TEXT_INPUT_2_PLACEHOLDER}
              value={formData.password}
              onChange={handleChange}
            />
          
              <Form.Text className="text-danger" style={{ marginLeft: "20px" }}>
                {formErrors.password}
              </Form.Text>
           
          </Form.Group>

          <Button className="global-button group-spacing" variant="primary" type="submit">
            {SIGN_IN_TEXT_BUTTON_1}
          </Button>
        </Form>
      </Col>
    </HalfScreenLogo>
  );
}

export default SignIn;
