import React, { createContext, useState, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import ToastContainer from "../../components/common/Toast/Toast";
import { ToastOptions } from "../../types/Toast.interface";


interface ToastContextType {
  showToast: (type: ToastOptions["type"], title: string, message: string) => void;
}

export const ToastContext = createContext<ToastContextType | undefined>(undefined);

const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastOptions[]>([]);

  const showToast = useCallback((type: ToastOptions["type"], title: string, message: string) => {
    const id = uuidv4();
    setToasts((prev) => [...prev, { id, type, title, message }]);
  }, []);

  const removeToast = useCallback((id: string) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <ToastContainer toasts={toasts} removeToast={removeToast} />
    </ToastContext.Provider>
  );
};

export default ToastProvider;