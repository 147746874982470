import React, { useEffect } from "react";
import "../../styles/components/SitesPage.scss";
import "../../styles/components/General.scss";
import { useTranslation } from "react-i18next";
import FormBuilder, { FormBuilderInterface } from "../common/FormBuilder";
import { ReactComponent as SearchIcon } from "../../common/assats/table/search.svg"

const siteAddressCreateConfig: FormBuilderInterface[] = [
  {
    name: "address",
    label: "sitePage.siteCreate.address",
    type: "text",
    required: true,
    translateOffset: false,
    translateLabel: true,
    fullRow: true,
  },
  {
    name: "city",
    label: "sitePage.siteCreate.city",
    type: "text",
    required: true,
    translateOffset: false,
    translateLabel: true,
  },
  {
    name: "state",
    label: "sitePage.siteCreate.state",
    type: "dropdown",
    required: false,
    translateOffset: false,
    optionsArray: ["add more options", "add more options 3"],
    translateLabel: true,
  },
  {
    name: "zipCode",
    label: "sitePage.siteCreate.zipCode",
    type: "text",
    required: false,
    translateOffset: false,
    translateLabel: true,
  },
  {
    name: "country",
    label: "sitePage.siteCreate.country",
    type: "text",
    required: true,
    translateOffset: false,
    translateLabel: true,
    icon: <SearchIcon />,
  },
];

interface SiteInformationCreateProps {
  values: { [key: string]: any };
  handleInputChange: (name: string, value: any) => void;
  errors: { [key: string]: string };
}

const SiteAddressCreate = ({
  values,
  handleInputChange,
  errors,
}: SiteInformationCreateProps) => {
  const { t } = useTranslation();

  useEffect(() => {
  }, []);

  return (
    <div>
      <FormBuilder
        fields={siteAddressCreateConfig}
        handleInputChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleInputChange(e.target.name, e.target.value)
        }
        values={values}
        showErrors={true}
        errors={errors}
        translateErrors={true}
      />
    </div>
  );
};

export default SiteAddressCreate;
