import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import "../../../styles/components/PropertySegment.scss";
type PropertySegmentParams = {
  title: string;
  textArray: string[];
  className?: string;
};
const PropertySegment: FC<PropertySegmentParams> = ({
  title,
  textArray,
  className = "",
}) => {
  const { t } = useTranslation();

  return (
    <div className={`property-segment ${className}`}>
      <div className="fw-normal fs-17 ">{title}</div>
      <div className="property-data">
        {textArray.map((text, i) => (
          i > 0 && text ? ` / ${text}` : text
        ))}
        </div>
    </div>
  );
};

export default PropertySegment;
