import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../common/contexts/UserContext";
import { Role } from "../common/consts/roles";
import { PrivateRouteProps } from "../types/privateRouteProps.interface";

const PrivateRoute: React.FC<PrivateRouteProps> = ({
    component: Component,
    componentName,
    redirectComponent: Rc,
    redirectPath = "/",
}) => {

    const { user } = useUser();
    const [authorized, setAuthorized] = useState("")

    if (typeof Rc === "function") Rc = <Rc />;

    if (user !== null && !user) return <Navigate to={redirectPath} />
    // console.log(user);
    return user === null ?
        <></>
        :
        (!user?.role || !user?.role.some(role => roleRoutePermissions[role]?.includes(componentName))) ?
            (Rc ?? <Navigate to={redirectPath} />) : typeof Component === "function" ?
                (<Component />) : (Component);
};

export default PrivateRoute;

const roleRoutePermissions: { [role in Role]: string[] } = {
    SuperAdmin: [
        "Dashboard",
        "Fota",
        "Devices",
        "Sites",
        "About",
        "Support",
        "Contact",
        "Settings",
        "UsersList",
        "SitesPageCreate",
        "UsersCreate"
    ],
    SiteAdmin: [
        "Dashboard",
        "PatientsList",
        "Patient",
        "Fota",
        "About",
        "Support",
        "Contact",
        "Settings",
        "Dashboard",
        "UsersList",
        "UsersCreate"
    ],
    ClinicalUser: [
        "PatientsList",
        "Patient",
        "Fota",
        "About",
        "Support",
        "Contact",
        "Settings",
        "Sites",
        "Dashboard",
        "Users"
    ],
    NonClinicalUser: [
        "PatientsList",
        "About",
        "Support",
        "Contact",
        "Settings"
    ],

}