import { useEffect, useState } from "react";
import MainHome from "../MainHome";
import "./../../styles/homePage/patient.scss";
import { useTranslation } from "react-i18next";
import { getPatient, getPatientClinical } from "../../common/api/apiCalls";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Tab, Tabs } from "react-bootstrap";
import InfoFieldsBuilder from "../common/InfoFieldsBuilder";
import { getPatientInfoFields } from "./PatientInfoFields";
import { logos } from "../../common/assats";
import GenericCards from "../common/Cards/GenericCards";

const Patient = () => {
  const { patientId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPatientData = async () => {
      try {
        setIsLoading(true);

        // Fetch patient info
        const patientData = await getPatient(patientId!);

        const patientUid = patientData.id;
        // Fetch clinical info
        const clinicalData = await getPatientClinical(patientUid!);

        // Combine both patient and clinical data  //not sure that this is the best approach
        const combinedData = {
          ...patientData,
          ...clinicalData,
        };

        setData(combinedData);
        setError(null);
      } catch (err) {
        setError("Failed to fetch patient data or clinical info.");
      } finally {
        setIsLoading(false);
      }
    };

    if (patientId) {
      fetchPatientData();
    } else {
      console.log("nope"); //remove client-side dev logging
    }
  }, [patientId]);

  const editPatient = () => {
    navigate(`Edit`, { state: data });
  };

  const cardsConfig = [
    {
      title: "Exam results",
      image: logos.halfScreenLogo,
      body: new Date().toDateString(),
    },
    {
      title: "Exam results",
      image: logos.halfScreenLogo,
      body: new Date().toDateString(),
    },
    {
      title: "Exam results",
      image: logos.halfScreenLogo,
      body: new Date().toDateString(),
    },
    {
      title: "Exam results",
      image: logos.halfScreenLogo,
      body: new Date().toDateString(),
    },
    {
      title: "Exam results",
      image: logos.halfScreenLogo,
      body: new Date().toDateString(),
    },
    {
      title: "Exam results",
      image: logos.halfScreenLogo,
      body: new Date().toDateString(),
    },
    {
      title: "Exam results",
      image: logos.halfScreenLogo,
      body: new Date().toDateString(),
    },
    {
      title: "Exam results",
      image: logos.halfScreenLogo,
      body: new Date().toDateString(),
    },
    {
      title: "Exam results",
      image: logos.halfScreenLogo,
      body: new Date().toDateString(),
    },
  ];

  return (
    <MainHome>
      <div className="patient">
        {!isLoading && (
          <>
            <div className="patient-header">
              <div className="identity-info">
                <div className="col-4">
                  <div className="d-flex flex-row align-items-end patient-name">
                    <h1>{`${data?.honorific} ${data?.firstName} ${data?.lastName}`}</h1>
                    <span onClick={editPatient}>{t("general.edit")}</span>
                  </div>
                  <div className="patient-id">
                    <div>{t("patientsList.patientId") + patientId}</div>
                  </div>
                  <div className="additonal-details rounded-status">
                    {data.examStatus}
                  </div>
                </div>
                <div className="col-8 d-flex align-items-end">
                  <InfoFieldsBuilder
                    fields={getPatientInfoFields(data).HEADER}
                  />
                </div>
              </div>
            </div>
            <div className="patient-info">
              <Tabs defaultActiveKey="demographic" id="patients-info-tabs">
                <Tab eventKey="demographic" title="Demographic Information">
                  <InfoFieldsBuilder
                    containerClassName="patient-info-container "
                    fields={getPatientInfoFields(data).DEMOGRAPHIC}
                  />
                </Tab>
                <Tab eventKey="clinical1" title="Clinical Information 1">
                  <InfoFieldsBuilder
                    containerClassName="patient-info-container "
                    fields={getPatientInfoFields(data).CLINICAL_ONE}
                  />
                </Tab>
                <Tab eventKey="clinical2" title="Clinical Information 2">
                  <InfoFieldsBuilder
                    containerClassName="patient-info-container "
                    fields={getPatientInfoFields(data).CLINICAL_TWO}
                  />
                </Tab>
                <Tab eventKey="exam" title="Exam Results">
                  <div className="patient-info-container">
                    <GenericCards cardsConfig={cardsConfig} />
                  </div>
                </Tab>
              </Tabs>
            </div>
          </>
        )}
      </div>
    </MainHome>
  );
};

export default Patient;
