import React, { FC } from "react";
import "../../styles/homePage/table/TableFilters.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as Trash } from "../../common/assats/table/trash.svg";
import { ReactComponent as FiltersIcon } from "../../common/assats/table/FiltersIcon.svg";
import { ReactComponent as TempExportIcon } from "../../common/assats/table/sortArrow.svg";
import { ReactComponent as Search } from "../../common/assats/table/search.svg";
import { ReactComponent as Plus } from "../../common/assats/table/plus.svg";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useFilters } from "../../common/contexts/FiltersContext";

const i18nPrefix = "table.filter";

export interface TableFiltersProps {
  tableHeadline?: string;
  trashAction?: () => void;
  filterAction?: () => void;
  searchValue?: string;
  setSearchValue?: (val: string) => void;
  searchPlaceholder?: string;
  buttonText?: string;
  buttonAction?: () => void;
  isExport?: boolean;
  exportAction?: () => void;
}
const TableFilters: FC<TableFiltersProps> = ({
  tableHeadline,
  trashAction,
  filterAction,
  searchValue,
  setSearchValue,
  searchPlaceholder,
  buttonText,
  buttonAction,
  isExport = false,
  exportAction,
}) => {
  const { t } = useTranslation();
  const { filtersCount } = useFilters();
  return (
    <div className="table-filters-main-container">
      {tableHeadline && (
        <div className="title-wrapper">
          <h3 className="patients-list-title">{tableHeadline}</h3>
        </div>
      )}

      <div className="filters-wrapper">
        {trashAction && (
          <div className="flex-center-container trash-button ">
            {/* <img className="image" src={menuIcons.arrow} /> */}
            <Trash onClick={trashAction} />
          </div>
        )}
        {filterAction && (
          <div
            onClick={filterAction}
            className={`flex-center-container filter-table-buttons p-3 ${
              filtersCount ? "active-filters-button " : ""
            }`}
          >
            {filtersCount ? <span className="filters-count">{filtersCount}</span> : null}
            <FiltersIcon onClick={filterAction} />
            <span>{t(`${i18nPrefix}.filter`)}</span>
          </div>
        )}

        {isExport && (
          <div
            onClick={exportAction}
            className="flex-center-container temp filter-table-buttons p-3"
          >
            <TempExportIcon onClick={exportAction} />
          </div>
        )}

        {setSearchValue && (
          <Form.Group
            className="search-input-wrapper"
            controlId="formBasicEmail"
          >
            <InputGroup>
              <InputGroup.Text
                className="search-input"
                style={{ backgroundColor: "white", border: "none" }}
              >
                <Search />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder={searchPlaceholder}
                style={{ border: "none" }}
                className="search-input"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </InputGroup>
          </Form.Group>
        )}

        {buttonAction && buttonText && (
          <Button
            onClick={buttonAction}
            className="add-button d-flex align-items-center justify-content-center gap-2"
            variant="primary"
            type="button"
          >
            <Plus />
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default TableFilters;
