import React, { useCallback, useEffect, useMemo, useState } from "react";
import MainHome from "../../components/MainHome";
import "./../../styles/homePage/patientsList.scss";
import TablePage from "../../components/genericTable/TablePage";
import { useTranslation } from "react-i18next";
import { TableCard } from "../../common/types/tableCardType";
import { ReactComponent as BeforeExam } from "../../common/assats/homeScreens/cardsIcons/beforeExam.svg";
import { ReactComponent as AfterExam } from "../../common/assats/homeScreens/cardsIcons/afterExam.svg";
import { ReactComponent as AfterConsultation } from "../../common/assats/homeScreens/cardsIcons/afterConsultation-2.svg";
import { useQuery } from "@tanstack/react-query";
import { getAllBranchPatients } from "../../common/api/apiCalls";
import { PatientDataInterface } from "../../common/types/patientDataType";
import { useNavigate } from "react-router-dom";
import Filter from "../../components/common/Filter/Filter";
import { debounce } from "../../common/function/debounce";
import { sysConfig } from "../../config";
import { NavigationPaths } from "../../common/consts/navigationPaths";
import { useFilters } from "../../common/contexts/FiltersContext";

type SortOptions =
  | "patientName"
  | "siteName"
  | "identityNumber"
  | "phoneNumber"
  | "medId"
  | "examStatus"
  | "birthday"
  | "lastVisit"
  | "gender";

const PatientsList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const [debouncedSearch, setDebouncedSearch] = useState<string>("");
  const [sortKey, setSortKey] = useState<string>("patientName");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const { activeFilters, clearFilters, handleCardFilters } = useFilters();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const { data: response, isPending, error } = useQuery({
    queryKey: [
      "patientsData",
      sortKey,
      sortOrder,
      activeFilters,
      debouncedSearch,
    ],
    queryFn: () => {
      return getAllBranchPatients(
        sortKey,
        sortOrder,
        activeFilters,
        debouncedSearch
      );
    },
  });
  const [patientCounts, setPatientCounts] = useState(response?.counts?.total);

  useEffect(() => {
    setPatientCounts(response?.counts?.total);
    setData();
  }, [response?.patients, patientCounts]);

  function setData() {
    const tdata = response?.patients?.map((patient: any) => {
      return {
        ...patient,
        tableProps: {
          id: patient.id,
          selected: false,
        },
      };
    });
    setTableData(tdata || []);
  }

  const cards: TableCard[] = [
    {
      headline: "BeforeExam",
      explanation: t("patientsList.cards.BeforeExamExplain"),
      icon: BeforeExam,
      value: response?.counts?.beforeExam,
      action: () => {
        handleCardFilters("examStatus", "BeforeExam");
      },
    },
    {
      headline: t("patientsList.cards.afterExam"),
      explanation: t("patientsList.cards.afterExamExplain"),
      icon: AfterExam,
      value: response?.counts?.afterExam,
      action: () => {
        handleCardFilters("examStatus", "AfterExam");
      },
    },
    {
      headline: t("patientsList.cards.afterConsultation"),
      explanation: t("patientsList.cards.afterConsultationExplain"),
      icon: AfterConsultation,
      value: response?.counts?.afterConsultation,
      action: () => {
        handleCardFilters("examStatus", "AfterConsultation");
      },
    },
  ];


  const filteredData = useMemo(() => {
    if (response?.success === false) {
      return [];
    }
    return (tableData || []).filter((patient: PatientDataInterface) => {
      const fullName = `${patient.firstName} ${patient.lastName}`.toLowerCase();
      const searchLower = search.toLowerCase();
      const nameMatches =
        fullName.includes(searchLower) ||
        (patient.identityNumber || "").toLowerCase().includes(searchLower) ||
        patient.medId?.toLowerCase().includes(searchLower);
      const idMatches =
        patient.medId?.toLowerCase().includes(searchLower) ||
        patient.identityNumber?.toLowerCase().includes(searchLower);
      const phoneMatches = (patient.phoneNumber || "").includes(searchLower);
      return nameMatches || idMatches || phoneMatches;
    });
  }, [tableData, search, response]);

  const handleCheckBoxClick = (selectedRows: any) => {
    setSelectedRows(selectedRows);
  };

  const navigateToAddPatient = () => {
    navigate(NavigationPaths.PATIENTS + "/Patient/Create");
  };

  const sortKeysArray: SortOptions[] = [
    "patientName",
    "identityNumber",
    "phoneNumber",
    "medId",
    "examStatus",
    "birthday",
    "lastVisit",
    "gender",
  ];


  const filtersObject = {
    examStatus: {
      label: "Exam Status",
      options: [
        { name: "BeforeExam", label: "Before Exam" },
        { name: "AfterExam", label: "After Exam" },
        { name: "AfterConsultation", label: "After Consultation" },
      ],
    },
    lastVisit: {
      label: "Last Visit",
      options: [
        { name: "last7Days", label: "Last 7 Days" },
        { name: "last14Days", label: "Last 14 Days" },
        { name: "last30Days", label: "Last 30 Days" },
        { name: "last3Months", label: "Last 3 Months" },
        { name: "last12Months", label: "Last 12 Months" },
      ],
    },
    gender: {
      label: "Gender",
      options: [
        { name: "male", label: "Male" },
        { name: "female", label: "Female" },
      ],
    },
  };

  const debouncedSetSearch = useCallback(
    debounce((value: string) => {
      setDebouncedSearch(value);
    }, sysConfig.searchDebounce),
    []
  );

  const handleSearchChange = (str: string) => {
    setSearch(str);
    debouncedSetSearch(str);
  };

  return (
    <MainHome>
      <Filter
        isOpen={isFiltersOpen}
        filtersObject={filtersObject}
        handleFilterClose={() => {
          setIsFiltersOpen(false);
        }}
      />

      <TablePage
        tableHeadline={t(`patientsList.patientsList`)}
        headerHeadline={t("patientsList.patients")}
        viewAllAction={clearFilters}
        cards={cards}
        className={`${isFiltersOpen ? "filter-open" : ""}`}
        totalCount={patientCounts}
        trashAction={clearFilters}
        onCheckBoxClick={handleCheckBoxClick}
        filterAction={() => {
          setIsFiltersOpen(!isFiltersOpen);
        }}
        searchValue={search}
        setSearchValue={handleSearchChange}
        searchPlaceholder={t("patientsList.Search_by_Name_or_ID")}
        buttonText={t("patientsList.AddPatient")}
        buttonAction={() => {
          navigateToAddPatient();
        }}
        setSortKey={setSortKey}
        sortKey={sortKey}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        tableData={filteredData}
        sortKeysArray={
          sortKeysArray
            ? sortKeysArray.map((value) => {
                return { name: t(`patientsList.sort.${value}`), val: value };
              })
            : []
        }
        onRowClick={(row: PatientDataInterface) =>
          navigate(`${NavigationPaths.PATIENTS}/Patient/${row.identityNumber}`)
        }
      />
    </MainHome>
  );
};

export default PatientsList;
