import React from 'react'
import MainHome from '../../components/MainHome'
import DashboardPage from '../../components/DashboardPage'

const Dashboard = () => {
    return (
        <MainHome>
            <DashboardPage />
        </MainHome>
    )
}

export default Dashboard