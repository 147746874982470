export function debounce(func: (...args: any[]) => void, delay: number) {
    let timeoutId: NodeJS.Timeout | null;
  
    return (...args: any[]) => {
      if (timeoutId) {
        clearTimeout(timeoutId); // Clear any existing timeout
      }
      timeoutId = setTimeout(() => {
        func(...args); // Execute the function after the delay
      }, delay);
    };
  }