import React from "react";
import "../../styles/components/FormBuilder.scss";
import { ClinicalInfoInterface } from "../../types/PatientData.interface";
import RadioToggleButton from "./inputs/RadioToggleButton";
import CustomDropdown from "./inputs/CustomDropdown";
import CustomTextInput from "./inputs/CustomTextInput";
import CustomDatePicker from "./DatePicker/DatePicker";
import nestLine from "../../common/assats/icons/nestLine.svg";
import { useTranslation } from "react-i18next";
import CustomNumberInput from "./inputs/CustomNumberInput";

export interface FormBuilderInterface {
  name: string;
  label?: string;
  type?: "text" | "dropdown" | "radio" | "date" | "search" | "number";
  colWidth?: string;
  suffix?: string;
  translateOffset?: boolean;
  offset?: string;
  optionsArray?: string[];
  question?: string;
  required?: boolean;
  nested?: number;
  translateLabel?: boolean;
  translateOptions?: boolean;
  fullRow?: boolean;
  icon?: React.ReactNode;
  isDisabled?: boolean;
}
export interface CustomDateChangeEvent {
  target: {
    name: string;
    value: Date | null;
  };
}

interface FormBuilderProps {
  fields: FormBuilderInterface[];
  handleInputChange: any;
  handleFocus?: any;
  handleBlur?: any;
  values: any;
  showErrors?: boolean;
  errors?: { [key: string]: string | null };
  translateErrors?: boolean;
}

const FormBuilder = (props: FormBuilderProps) => {
  const { t } = useTranslation();
  const {
    fields,
    handleInputChange,
    values,
    showErrors,
    errors,
    handleBlur,
    handleFocus,
  } = props;
  const booleanDisplay = (value: boolean | undefined | string): string => {
    return typeof value === "boolean"
      ? value
        ? "Yes"
        : "No"
      : value
      ? value
      : "";
  };

  const translateOptions = (options: string[]) => {
    return options.map((option) => t(option));
  };

  return (
    <div className="container generic-form">
      <div className="row">
        {fields.map((field, i) => (
          <div
            className={`${field.nested ? "has-nested" : ""}${
              field.offset ? ` offset-${field.offset}` : ""
            } ${field.fullRow ? "col-sm-12" : field.colWidth ?? "col-sm-4"} ${
              field.translateOffset ? "translate-offset" : ""
            } mb-3`}
            key={`${field.name}${i}`}
          >
            {field.nested && <img src={nestLine} className="nest-line" />}
            <div className={`form-group w-100`}>
              {field.type === "dropdown" ? (
                <CustomDropdown
                  name={field.name}
                  label={field.translateLabel ? t(field.label!) : field.label}
                  optionsArray={
                    field.optionsArray
                      ? field.translateOptions
                        ? translateOptions(field.optionsArray!)
                        : field.optionsArray
                      : []
                  }
                  value={values[field.name]}
                  error={errors?.[field.name] || ""}
                  handleInputChange={handleInputChange}
                  handleBlur={handleBlur}
                  showErrors={showErrors}
                  required={field.required}
                  handleFocus={handleFocus}
                  disabled={field.isDisabled}
                />
              ) : field.type === "text" ? (
                <CustomTextInput
                  name={field.name}
                  label={field.translateLabel ? t(field.label!) : field.label}
                  value={values[field.name]}
                  error={errors?.[field.name] || ""}
                  handleInputChange={handleInputChange}
                  showErrors={showErrors}
                  handleBlur={handleBlur}
                  suffix={field.suffix}
                  required={field.required}
                  handleFocus={handleFocus}
                  translateErrors={props.translateErrors}
                  icon={field.icon}
                  disabled={field.isDisabled}
                />
              ) : field.type === "number" ? (
                <CustomNumberInput
                  name={field.name}
                  label={field.translateLabel ? t(field.label!) : field.label}
                  value={values[field.name]}
                  error={errors?.[field.name] || ""}
                  handleInputChange={handleInputChange}
                  showErrors={showErrors}
                  handleBlur={handleBlur}
                  required={field.required}
                  handleFocus={handleFocus}
                  translateErrors={props.translateErrors}
                  icon={field.icon}
                  disabled={field.isDisabled}
                />
              ) : field.type === "date" ? (
                <CustomDatePicker
                  name={field.name}
                  label={field.translateLabel ? t(field.label!) : field.label}
                  handleBlur={handleBlur}
                  value={values[field.name]}
                  error={errors?.[field.name] || ""}
                  handleInputChange={handleInputChange}
                  showErrors={showErrors}
                  handleFocus={handleFocus}
                  required={field.required}
                  disabled={field.isDisabled}
                />
              ) : field.type === "radio" ? (
                <div
                  className={`toggle-question ${
                    field.nested ? `nested-${field.nested}` : ""
                  }`}
                  key={field.name}
                >
                  <div className="question-text">{field.question}</div>
                  <RadioToggleButton
                    name={field.name as keyof ClinicalInfoInterface}
                    optionsArray={field.optionsArray!}
                    selectedOption={booleanDisplay(
                      values[field.name as keyof typeof values]
                    )}
                    handleInputChange={handleInputChange}
                    disabled={field.isDisabled}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FormBuilder;
