import React, { FC } from 'react';
import "../styles/homePage/mainHome.scss"
import { homeScreenImages } from '../common/assats';

import MenuSideBar from '../pages/Menu';

interface MainHomeProps {
    children: React.ReactNode;
}
const MainHome: FC<MainHomeProps> = ({ children }) => {
    return (
        <div className='main-home-container full-width-height'>
            <div className='header global-gradient'>
                <div className='logo'>
                    <img src={homeScreenImages.coraLogo} />
                </div>
            </div>
            <div className='content-container'>
                <MenuSideBar />
                <div className='content position-relative'>
                    {children}
                </div>

            </div>
        </div>
    )
}

export default MainHome