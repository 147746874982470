import "../../styles/homePage/table/tablePage.scss"
import TableHeader, { TableHeaderParams } from './TableHeader'
import { TableFiltersProps } from './TableFilters'
import { CardsProps } from './TableHeaderCards'
import { SortTableProps } from './SortTable'
import { TablePageParams, TableProps, TableRowBase } from '../../types/Table.interface'
import Table from "./Table"

const TablePage = <T extends TableRowBase>({
    trashAction,
    totalCount,
    viewAllAction,
    filterAction,
    searchValue,
    setSearchValue,
    searchPlaceholder,
    buttonText,
    buttonAction,
    headerHeadline,
    tableHeadline,
    cards,
    setSortKey,
    sortKey,
    tableData,
    sortKeysArray,
    setSortOrder,
    sortOrder,
    onRowClick,
    onCheckBoxClick,
    className
}: TablePageParams & TableFiltersProps & TableHeaderParams & CardsProps & Partial<SortTableProps> & TableProps<T>) => {
    return (
        <div className={`table-page-main-container ${className ? className : ''}`}>
            <div className='table-header'>
                <TableHeader
                    headerHeadline={headerHeadline}
                    cards={cards}
                    totalCount={totalCount}
                    viewAllAction={viewAllAction}
                />
            </div>
            <div className='table-content'>

                <Table
                    setSortOrder={setSortOrder}
                    sortOrder={sortOrder}
                    tableHeadline={tableHeadline}
                    trashAction={trashAction}
                    filterAction={filterAction}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    searchPlaceholder={searchPlaceholder}
                    buttonText={buttonText}
                    buttonAction={buttonAction}
                    tableData={tableData}
                    setSortKey={setSortKey}
                    sortKey={sortKey}
                    sortKeysArray={sortKeysArray}
                    onRowClick={onRowClick}
                    onCheckBoxClick={onCheckBoxClick}
                />
            </div>
        </div>
    )
}

export default TablePage