import React, { useEffect, useMemo, useState } from "react";
import "../../styles/components/SitesPage.scss";
import "../../styles/components/General.scss";
import { useTranslation } from "react-i18next";
import FormBuilder, { FormBuilderInterface } from "../common/FormBuilder";
import { Button } from "react-bootstrap";

interface ButtonProps {
  type: "button" | "submit" | "reset";
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  isLoading?: boolean;
  context: string;
}

const ButtonCustom = ({
  type,
  onClick,
  disabled,
  className,
  isLoading,
  context,
}: ButtonProps) => {
  return (
    <div className="custom-button">
      <Button type={type} className={`btn ${className}`} disabled={isLoading || disabled} onClick={onClick}>
        {context}
      </Button>
    </div>
  );
};

export default ButtonCustom;
