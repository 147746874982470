import { InfoFieldsBuilderInterface } from "../common/InfoFieldsBuilder";
import {
  ClinicalInfoInterface,
  DemographicInfoInterface,
} from "../../types/PatientData.interface";
type PatientData = ClinicalInfoInterface & DemographicInfoInterface;

export const getHeaderFields = (
  data: PatientData
): InfoFieldsBuilderInterface[] => [
  {
    title: "Phone Number",
    textArray: [data?.phoneNumber ?? ""],
    colWidth: "col-sm-3",
  },
  {
    title: "Medical ID Number",
    textArray: [`${data?.medId ?? ""}`],
    colWidth: "col-sm-3",
  },
  {
    title: "DOB",
    textArray: [data?.birthday ?? ""],
    colWidth: "col-sm-3",
  },
  {
    title: "Last Visit Date",
    textArray: [data?.lastVisit ?? "N/A"],
    colWidth: "col-sm-3",
  },
];

export const getDemographicFields = (
  data: PatientData
): InfoFieldsBuilderInterface[] => [
  {
    title: "Email",
    textArray: [data?.email ?? ""],
    colWidth: "col-sm-3",
  },
  {
    title: "Gender",
    textArray: [data?.gender ?? ""],
    colWidth: "col-sm-3",
  },
  {
    title: "Marital Status",
    textArray: [data?.maritalStatus ?? ""],
    colWidth: "col-sm-3",
  },
  {
    title: "Race / Ethnicity",
    textArray: [data?.race ?? ""],
    colWidth: "col-sm-3",
  },
  {
    title: "Address",
    textArray: [
      `${data?.address ?? ""} ${data?.city ?? ""} ${data?.zip ?? ""}`,
    ],
    fullRow: true,
  },
  {
    title: "Notes",
    textArray: [data?.notes ?? ""],
    fullRow: true,
  },
];

const getClinicalOneFields = (
  data: PatientData
): InfoFieldsBuilderInterface[] => [
  {
    title: "Symptomatic",
    textArray: [data.isSymptomatic ? "Yes" : "No"],
    colWidth: "col-sm-3",
  },
  {
    title: "Symptom type",
    textArray: [data?.symptomsType ?? ""],
    colWidth: "col-sm-3",
  },
  {
    title: "Symptoms",
    textArray: [
      data.chestPainType === "Typical"
        ? "Typical chest pain"
        : data.chestPainType === "Atypical"
        ? "Atypical chest pain"
        : "",
      data.shortnessOfBreath ? "Shortness of breath" : "",
      data.shoulderOrArmPain ? "Shoulder or arm pain" : "",
      data.jawPain ? "Jaw pain" : "",
      data.dizzinessOrPresyncope ? "Dizziness or pre-syncope" : "",
      data.nausea ? "Nausea" : "",
    ],
    colWidth: "col-sm-6",
  },
  {
    title: "BMI",
    //this calc belongs in a submethod calculateBMI()
    textArray: [
      (
        data?.weight ??
        1 / (parseInt(data?.height ?? "1") * parseInt(data?.height ?? "1"))
      ).toString() ?? "",
    ],
    colWidth: "col-sm-3",
  },
  {
    title: "Smoking",
    textArray: [data?.smokingStatus ?? ""],
    colWidth: "col-sm-3",
  },
  {
    title: "BP",
    textArray: [`${data?.bpSystolic ?? ""} / ${data?.bpDiastolic ?? ""}`],
    colWidth: "col-sm-3",
  },
  {
    title: "COPD",
    textArray: [data.copd ? "Yes" : "No"],
    colWidth: "col-sm-3",
  },
  {
    title: "Total Cholesterol",
    textArray: [data?.totalCholesterol?.toString() ?? ""],
    colWidth: "col-sm-3",
  },
  {
    title: "LDL",
    textArray: [data?.ldl?.toString() ?? ""],
    colWidth: "col-sm-3",
  },
  {
    title: "Triglycerides",
    textArray: [data?.triglycerides?.toString() ?? ""],
    colWidth: "col-sm-3",
  },
  {
    title: "Sugar",
    textArray: [data?.sugarLevel?.toString() ?? ""],
    colWidth: "col-sm-3",
  },
  {
    title: "Arrhtimias / conduction abnormalities",
    textArray: [data.arrhythmiasOrConductionAbnormality ? "Yes" : "No"],
    colWidth: "col-sm-3",
  },
  {
    title: "Cardiac family history",
    textArray: [data.familyHistoryCardiovascularEvents ? "Yes" : "No"],
    colWidth: "col-sm-3",
  },
  {
    title: "CHF",
    textArray: [data.chronicHeartFailure ? "Yes" : "No"],
    colWidth: "col-sm-3",
  },
  {
    title: "LVEF <30%",
    textArray: [data.lvefLessThan30 ? "Yes" : "No"],
    colWidth: "col-sm-3",
  },
  {
    title: "PAD",
    textArray: [data.peripheralArterialDisease ? "Yes" : "No"],
    colWidth: "col-sm-3",
  },
  {
    title: "Pulmonary edema",
    textArray: [data.pulmonaryEdema ? "Yes" : "No"],
    colWidth: "col-sm-3",
  },
  {
    title: "Anemia",
    textArray: [data.anemia ? "Yes" : "No"],
    colWidth: "col-sm-3",
  },
];

const getClinicalTwoFields = (
  data: PatientData
): InfoFieldsBuilderInterface[] => [
  {
    title: "Cerebrovascular Disease ",
    textArray: [data.cerebrovascularDisease ? "Yes" : "No"],
    colWidth: "col-3",
  },
  {
    title: "Stroke / TIA within 6 months",
    textArray: [data.strokeOrTiaOrRIND ? "Yes" : "No"],
    colWidth: "col-3",
  },
  {
    title: "Hemodynamic Instability",
    textArray: [data.hemodynamicInstability ? "Yes" : "No"],
    colWidth: "col-3",
  },
  {
    title: "Medications",
    textArray: [data.takingOtherDrugs ? "Yes" : "No"],
    colWidth: "col-3",
  },
  {
    title: "Notes",
    textArray: [data?.notes ?? ""],
    colWidth: "col-12",
  },
];

export const getPatientInfoFields = (data: PatientData) => ({
  HEADER: getHeaderFields(data),
  DEMOGRAPHIC: getDemographicFields(data),
  CLINICAL_ONE: getClinicalOneFields(data),
  CLINICAL_TWO: getClinicalTwoFields(data),
});
