import { Col, Form, Button } from 'react-bootstrap';
import '../../styles/loginStyles/personalInfo.scss';

import { Link, useNavigate } from 'react-router-dom';
import HalfScreenLogo from '../../components/HalfScreenLogo';
import i18n from "../../i18n/i18n"
import { postPersonalInfo } from '../../common/api/apiCalls';


const positionOptions: { text: string, value: string }[] = [
    { text: "position 1", value: "position 1" },
    { text: "position 2", value: "position 2" },
    { text: "position 3", value: "position 3" },
    { text: "position 4", value: "position 4" },
    { text: "position 5", value: "position 5" },
]
const PersonalInfo = () => {
    const navigate = useNavigate();
    const SIGN_IN_TEXT_MAIN_TITLE = i18n.t('personal_info.SIGN_IN_TEXT_MAIN_TITLE');
    const SIGN_IN_TEXT_SUB_TITLE = i18n.t('personal_info.SIGN_IN_TEXT_SUB_TITLE');
    const SIGN_IN_TEXT_INPUT_1_PLACEHOLDER = i18n.t('personal_info.SIGN_IN_TEXT_INPUT_1_PLACEHOLDER');
    const SIGN_IN_TEXT_INPUT_2_PLACEHOLDER = i18n.t('personal_info.SIGN_IN_TEXT_INPUT_2_PLACEHOLDER');
    const SIGN_IN_TEXT_INPUT_3_PLACEHOLDER = i18n.t('personal_info.SIGN_IN_TEXT_INPUT_3_PLACEHOLDER');
    const SIGN_IN_TEXT_INPUT_4_PLACEHOLDER = i18n.t('personal_info.SIGN_IN_TEXT_INPUT_4_PLACEHOLDER');
    const SIGN_IN_TEXT_INPUT_5_PLACEHOLDER = i18n.t('personal_info.SIGN_IN_TEXT_INPUT_5_PLACEHOLDER');
    const SIGN_IN_TEXT_BUTTON_1 = i18n.t('personal_info.SIGN_IN_TEXT_BUTTON_1');
    const SIGN_IN_TEXT_BUTTON_2 = i18n.t('personal_info.SIGN_IN_TEXT_BUTTON_2');
    const SIGN_IN_TEXT_LABEL_1 = i18n.t('personal_info.SIGN_IN_TEXT_LABEL_1');

    function checkText(
        firstName: string,
        middleName: string,
        lastName: string,
        position: string,
        phoneNumber: string
    ) {
        //check phone 
        const ilPhoneNumberPattern = /(\+972-5\d-\d{7}|05\d-\d{7})/;
        if (!ilPhoneNumberPattern.test(phoneNumber)) return false
        //check firstName 
        if (!firstName.length) return false
        //check middleName
        if (!middleName.length) return false
        //check lastName 
        if (!lastName.length) return false
        //check position 
        if (!position.length) return false

        return true
    }
    const onSubmit = async (event: any) => {
        event.preventDefault();

        // Handle form submission logic here
        const formData = new FormData(event.target);
        const firstName = formData.get('firstName') as string;
        const middleName = formData.get('middleName') as string;
        const lastName = formData.get('lastName') as string;
        const position = formData.get('position') as string;
        const phoneNumber = formData.get('phone') as string;
        // Example: Log email and password to the console
        const res = await postPersonalInfo({ firstName, middleName, lastName, position, phoneNumber })
        if (res.success)
            navigate("/Success/registration")
        // You can add more logic here, like making an API call to sign in
    };

    return (
        <HalfScreenLogo>
            <Col className="right-part d-flex align-items-center justify-content-center">
                <Form className="form-container" onSubmit={onSubmit}>
                    <Form.Group className='title-group' style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label className="global-big-label text-1o">{SIGN_IN_TEXT_MAIN_TITLE}</Form.Label>
                        <Form.Label className='global-sub-label text-3o'>{SIGN_IN_TEXT_SUB_TITLE}</Form.Label>
                    </Form.Group>
                    <Form.Group className='group-spacing' controlId="formBasicEmail">
                        <Form.Control className='global-input' type="text" name="firstName" placeholder={SIGN_IN_TEXT_INPUT_1_PLACEHOLDER} />
                    </Form.Group>
                    <Form.Group className='group-spacing' controlId="formBasicEmail">
                        <Form.Control className='global-input' type="text" name="middleName" placeholder={SIGN_IN_TEXT_INPUT_2_PLACEHOLDER} />
                    </Form.Group>
                    <Form.Group className='group-spacing' controlId="formBasicEmail">
                        <Form.Control className='global-input' type="text" name="lastName" placeholder={SIGN_IN_TEXT_INPUT_3_PLACEHOLDER} />
                    </Form.Group>
                    <Form.Group className='group-spacing' controlId="formBasicEmail" >
                        <Form.Select size="lg" className='global-input' name="position">
                            {positionOptions.map(val =>
                                <option>{val.text}</option>
                            )}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='group-spacing' controlId="formBasicEmail">
                        <Form.Control className='global-input' type="phone" name="phone" placeholder={SIGN_IN_TEXT_INPUT_5_PLACEHOLDER} />
                    </Form.Group>



                    <Button className='global-button group-spacing' variant="primary" type="submit">
                        {SIGN_IN_TEXT_BUTTON_1}
                    </Button>

                </Form>
            </Col>
        </HalfScreenLogo>
    );

}

export default PersonalInfo