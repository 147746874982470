import React from 'react'
import MainHome from '../../components/MainHome'

const Contact = () => {
    return (
        <MainHome>
            <div>Contact</div>
        </MainHome>
    )
}

export default Contact