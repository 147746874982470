import React from "react";
import "../../../styles/components/Dropdown.scss";

interface CustomDropdownProps {
  value: string | number;
  required?: boolean;
  showErrors?: boolean;
  name: string;
  handleInputChange: React.ChangeEventHandler<HTMLSelectElement>;
  handleFocus?: any;
  label?: string;
  optionsArray: Array<string | number>;
  setLabel?: boolean;
  withPlaceHolder?: boolean;
  handleBlur?: (name: string) => void;
  error?: string;
  disabled?: boolean;
}

const CustomDropdown = (props: CustomDropdownProps) => {
  const {
    name,
    value,
    required,
    error,
    showErrors,
    handleInputChange,
    label,
    optionsArray,
    handleBlur,
    handleFocus,
    withPlaceHolder = true
  } = props;

  const setLabel = value;
  return (
    <>

    <div className={`dropdown-wrapper ${setLabel && "set-label"} ${error && showErrors ? 'invalid-field' : ''}`}>
      {withPlaceHolder && label && (
        <div className="custom-dropown-label" >
          {label}
          {required && <div className="required-asterisk">*</div>}
        </div>
      )}

      <select
        className={`form-control custom-select ${
          showErrors && required && !value && error ? "is-invalid" : ""
        }`}
        name={name}
        id={name}
        value={value || ""}
        onChange={handleInputChange}
        onBlur={() => handleBlur && handleBlur(name)}
        onFocus={handleFocus}
        disabled={props.disabled}
      >
        <option value="" disabled hidden>
          {""}
        </option>
        {optionsArray?.map((option, index) => (
          <option key={index} value={option} className="custom-option">
            {option}
          </option>
        ))}
      </select>

      <div className="custom-dropdown-arrow">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
          <path
            d="M7 8C6.91244 8 6.8318 7.9833 6.75806 7.9499C6.67972 7.9165 6.60829 7.86639 6.54378 7.79959L1.17972 2.12372C1.05991 1.99489 1 1.83981 1 1.65849C1 1.53442 1.02765 1.42468 1.08295 1.32924C1.13825 1.22904 1.21429 1.15031 1.31106 1.09305C1.40323 1.03102 1.50922 1 1.62903 1C1.79954 1 1.94931 1.06442 2.07834 1.19325L7 6.40389L11.9217 1.19325C12.0507 1.06442 12.2005 1 12.371 1C12.4908 1 12.5968 1.03102 12.6889 1.09305C12.7857 1.15031 12.8618 1.22904 12.9171 1.32924C12.9724 1.42468 13 1.53442 13 1.65849C13 1.83981 12.9378 1.99489 12.8134 2.12372L7.45622 7.79959C7.39171 7.86639 7.32258 7.9165 7.24885 7.9499C7.17051 7.9833 7.08756 8 7 8Z"
            fill="#C8C8C8"
            stroke="#757575"
            strokeWidth="1.5"
          />
        </svg>
      </div>
    </div>
    {showErrors && error && (
          <div className="invalid-feedback">{error}</div>
        )}
    </>
  );
};

export default CustomDropdown;
