
import axios, { Method } from 'axios';
import { useUser } from '../contexts/UserContext';
import Cookies from 'js-cookie';
// import { calculateHMACSHA256, verifyHash } from './hash';

const urlsPath = "http://localhost:8080";


let refreshToken: string | null = null;
let token: string | undefined
let patientIndex: string | null = null;
let otpToken: string | null = null;

async function setTokenFromCookies() {
    const cookieToken = await Cookies.get("access_token")
    token = cookieToken
}


setTokenFromCookies()
// export async function setUserToken() {
//     token = await localStorage.getItem("token")
//     refreshToken = await localStorage.getItem("refreshToken")
//     patientIndex = await localStorage.getItem("patientIndex")
//     if (token === null || refreshToken === null || patientIndex === null) console.log("err")
//     setRefreshedAuthData(token || '', refreshToken || '', patientIndex || undefined)
// }

// setUserToken()

const apiInstance = axios.create({
    timeout: 10000,
    headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
    },
    withCredentials: true,



});

setAPIBaseURL()

const setRefreshedAuthData = async (authToken: string, rToken: string, patientI?: string) => {
    setAPIAuthToken(authToken);
    setAPIRefreshToken(rToken);
    if (patientI)
        patientIndex = patientI
};

function setAPIAuthToken(authToken: string) {
    token = authToken
    apiInstance.defaults.headers.common.Authorization = `${authToken}`;
}

const setAPIRefreshToken = async (rToken: string) => {
    refreshToken = `${rToken}`;
    await localStorage.setItem("refreshToken", rToken)
};

function removeAPIAuthToken() {
    refreshToken = null
    delete apiInstance.defaults.headers.common.Authorization;
}

function setAPITimeout(timeout: number) {
    apiInstance.defaults.timeout = timeout;
}

function setAPIBaseURL() {
    apiInstance.defaults.baseURL = urlsPath;
}

// apiInstance.interceptors.request.use(async function (config) {
//     // Get token before request is sent
//     // console.log(config);
//     try {
//         await getRefreshToken()
//     } catch (err) {
//         console.log("err", err)
//     }
//     return config;
// }, function (error) {
//     return Promise.reject(error);
// });

async function API_Root(url: string, method: Method, data: any) {
    const dataJson = JSON.stringify(data, null, 2);
    let isToken

    try {
        return await apiInstance
            .request({
                url,
                method,
                data: dataJson,
                validateStatus: function (status_1) {
                    return (status_1 >= 200 && status_1 < 300) || (status_1 >= 400 && status_1 < 500) || status_1 == 504;
                },
            });
    } catch (error: any) {
        console.log({ error });

        // if (config.debug) {
        const captureMessage = `API: ${url}, ${method}, ${JSON.stringify(
            data
        )}, ${JSON.stringify(error)} ${error.response?.data?.message ?? "-"}`;
        // eslint-disable-next-line no-console
        console.log(captureMessage, "debug", Object.keys(error));
        return await Promise.reject(error);
    }
}




/*=============================GENERIC FUNCTIONS======================================*/

async function makePostRequest(url: string, data: any) {
    try {
        const res = await apiInstance
            .request({
                url,
                method: "POST",
                data,
                withCredentials: true,

                headers: { Authorization: token }
            });
        if (url === "/users/Login") {
            setTokenFromCookies()
        }
     
        return res
    } catch (error) {
        console.log(error)
        // if (config.debug) {
        const captureMessage = `apiPostRequest (err): ${url}, ${JSON.stringify(
            error
        )}`;
        // eslint-disable-next-line no-console
        console.log(captureMessage, "debug");
        return await Promise.reject(error);
    }
}

async function makeGetRequest(url: string, params: any = undefined) {

    // if (config.debug) {
    // }


    try {
        return await apiInstance
            .request({
                url,
                method: "GET",
                params,
                headers: { Authorization: token }
            });
    } catch (error) {
        // if (config.debug) {
        const captureMessage = `apiGetRequest (err): ${url}, ${JSON.stringify(
            error
        )}`;
        // eslint-disable-next-line no-console
        console.log(captureMessage, "debug");
        return await Promise.reject(error);
    }
}

async function makeDeleteRequest(url: string, data: any = {}) {
    try {
        return await apiInstance
            .request({
                url,
                method: "DELETE",
                data,
            });
    } catch (error) {
        // if (config.debug) {
        const captureMessage = `apiGetRequest: ${url}, ${JSON.stringify(
            error
        )}`;
        // eslint-disable-next-line no-console
        console.log(captureMessage, "debug");
        return await Promise.reject(error);
    }
}

async function makeUploadRequest(url: string, data: any) {
    const dataJson = JSON.stringify(data, null, 2);

    try {
        return await apiInstance
            .request({
                url,
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                data,
                validateStatus: (status_1) => (status_1 >= 200 && status_1 < 300) || (status_1 >= 400 && status_1 < 500),
            });
    } catch (error) {
        console.log({ error });

        // if (config.debug) {
        const captureMessage = `apiUploadRequest: ${url}\n ${JSON.stringify(
            error,
            null,
            2
        )}`;
        // eslint-disable-next-line no-console
        console.log(captureMessage, "debug");
        return await Promise.reject(error);
    }
}


export {
    urlsPath,
    API_Root,
    makeGetRequest,
    makePostRequest,
    setAPIRefreshToken,
    setAPIAuthToken,
    removeAPIAuthToken,
    setAPITimeout,
    // setAPIBaseURL,
    makeUploadRequest,
    makeDeleteRequest,
    setRefreshedAuthData,
    refreshToken,
    patientIndex,
};