export enum HonorificEnum {
    "Mr" = "Mr",
    "Miss" = "Miss",
    "Mrs" = "Mrs",
    "Dr" = "Dr",
    "Other" = "Other"
}

export enum GenderEnum {
    "Male" = "Male",
    "Female" = "Female",
    "Other" = "Other"
}

export enum MaritalStatusEnum {
    Single = "Single",
    Married = "Married",
    Widowed = "Widowed",
    Divorced = "Divorced",
    Separated = "Separated",
    RegisteredPartnership = "Registered Partnership"
}