import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SiteInformationCreate from "./SiteCreateInformation";
import {
  SiteAddressProps,
  SiteInformationProps,
  SiteProps,
} from "../../common/interfaces/Sites.interface";
import { siteValidationSchemas } from "../../common/validations/SiteValidationsSchemas";
import * as Yup from "yup";
import SiteAddressCreate from "./SiteCreateAddress";
import { ReactComponent as InfoIcon } from "../../common/assats/icons/infoIcon.svg";
import { ReactComponent as MapIcon } from "../../common/assats/icons/addressIcon.svg";
import ButtonCustom from "../common/Button";
import { Metrics, SiteStepsEnum } from "../../common/enums/generalEnum";
import {
  createNewSite,
  updateSite,
  getUserByEmailForSite,
} from "../../common/api/apiCalls";
import { useToast } from "../../common/contexts/useToast";
import { useLocation, useNavigate } from "react-router-dom";
import MainHome from "../MainHome";

const SitesPageCreate = () => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const siteToEdit = location.state || null;
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [siteInformationData, setSiteInformationData] =
    useState<SiteInformationProps>({
      siteName: "",
      siteNumber: "",
      superUserFullName: "",
      superUserEmail: "",
      superUserPhone: "",
      siteStatus: "Active",
      metrics: "",
      id: "",
    });

  const [siteAddressData, setSiteAddressData] = useState<SiteAddressProps>({
    address: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });

  useEffect(() => {
    if (!siteToEdit || !siteToEdit?.siteName){
      setIsEdit(false);
      return;
    };
    const metrics =
      siteToEdit?.metrics.length &&
      (siteToEdit?.metrics === Metrics.metricsImperial
        ? t("sitePage.siteCreate.metricsImperial")
        : t("sitePage.siteCreate.metricsMetric"));
    setIsEdit(true);
    setSiteInformationData({
      siteName: siteToEdit.siteName,
      siteNumber: siteToEdit.siteNumber,
      superUserFullName: siteToEdit.siteAdmin?.fullName,
      superUserEmail: siteToEdit.siteAdmin?.username,
      superUserPhone: siteToEdit.siteAdmin?.userInfo?.phoneNumber,
      siteStatus: siteToEdit.siteStatus,
      metrics: metrics,
      id: siteToEdit.id,
    });
    setSiteAddressData({
      address: siteToEdit.address,
      city: siteToEdit.city,
      state: siteToEdit.state,
      zipCode: siteToEdit.zipCode,
      country: siteToEdit.country,
    });
  }, [siteToEdit]);

  const navigateToSites = () => {
    navigate("/Home/Sites");
    return;
  };

  const [activeStep, setActiveStep] = useState<SiteStepsEnum>(
    SiteStepsEnum.SITE_INFORMATION
  );

  const stepConfig = {
    [SiteStepsEnum.SITE_INFORMATION]: {
      schema: siteValidationSchemas[SiteStepsEnum.SITE_INFORMATION],
      data: siteInformationData,
    },
    [SiteStepsEnum.SITE_ADDRESS]: {
      schema: siteValidationSchemas[SiteStepsEnum.SITE_ADDRESS],
      data: siteAddressData,
    },
  };

  const handleInformationChange = (name: string, value: any) => {
    setSiteInformationData((prevData: SiteInformationProps) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddressChange = (name: string, value: any) => {
    setSiteAddressData((prevData: SiteAddressProps) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleNext = async () => {
    try {
      const { schema, data } = stepConfig[activeStep];
      await schema.validate(data, { abortEarly: false });
      setErrors({});
      if (isEdit) {
        const user = await getUserByEmailForSite(
          siteInformationData.superUserEmail
        );
        if (!user) {
          showToast(
            "danger",
            "Error",
            t("sitePage.siteCreate.errors.userNotFoundByEmail")
          );
          return;
        }
      }
      setActiveStep(SiteStepsEnum.SITE_ADDRESS);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors: { [key: string]: string } = {};
        err.inner.forEach((error) => {
          if (error.path) {
            validationErrors[error.path] = error.message;
          }
        });
        setErrors(validationErrors);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      const siteAddressSchema =
        siteValidationSchemas[SiteStepsEnum.SITE_ADDRESS];
      const siteInformationSchema =
        siteValidationSchemas[SiteStepsEnum.SITE_INFORMATION];
      await siteAddressSchema.validate(siteAddressData, { abortEarly: false });
      await siteInformationSchema.validate(siteInformationData, {
        abortEarly: false,
      });

      let metrics = "";
      if (
        siteInformationData.metrics
          ?.toLowerCase()
          .includes(Metrics.metricsImperial)
      ) {
        metrics = Metrics.metricsImperial;
      } else if (
        siteInformationData.metrics
          ?.toLowerCase()
          .includes(Metrics.metricsMetric)
      ) {
        metrics = Metrics.metricsMetric;
      }

      const dataToSubmit:SiteProps = {
        ...siteInformationData,
        ...siteAddressData,
        metrics,
      };
      let res = null;
      if (isEdit) {
        const { superUserEmail, ...updatedDataToSubmit } = dataToSubmit;
        res = await updateSite(updatedDataToSubmit);
      } else {
        res = await createNewSite(dataToSubmit);
      }
      if (res.success) {
        showToast(
          "success",
          "Success",
          isEdit
            ? t("sitePage.siteCreate.siteUpdateSuccess")
            : t("sitePage.siteCreate.siteCreateSuccess")
        );
        navigateToSites();
      } else {
        showToast("danger", "Error", t(res.data.errorMessage));
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors: { [key: string]: string } = {};
        err.inner.forEach((error) => {
          if (error.path) {
            validationErrors[error.path] = error.message;
          }
        });
        setErrors(validationErrors);
      }
    }
  };

  return (
    <MainHome>
      <div className="page-container w-100 h-100 p-5 border rounded background-primary-white">
        <div className="container pr-5 pl-5 h-100 d-flex flex-column">
          <div className="mb-4">
            <h3 className="fw-bold">
              {isEdit
                ? t("sitePage.siteCreate.editSite")
                : t("sitePage.siteCreate.addNewSite")}
            </h3>
            <hr className="my-4" />
            <div className="ms-2 d-flex align-items-end">
              {activeStep === SiteStepsEnum.SITE_INFORMATION ? (
                <>
                  <InfoIcon />
                  <h4 className="ms-2">
                    {t("sitePage.siteCreate.siteInformation")}
                  </h4>
                </>
              ) : (
                <>
                  <MapIcon />
                  <h4 className="ms-2">{t("sitePage.siteCreate.address")}</h4>
                </>
              )}
            </div>
          </div>

          <div className="flex-grow-1">
            {activeStep === SiteStepsEnum.SITE_INFORMATION ? (
              <SiteInformationCreate
                values={siteInformationData}
                handleInputChange={handleInformationChange}
                errors={errors}
              />
            ) : (
              <SiteAddressCreate
                values={siteAddressData}
                handleInputChange={handleAddressChange}
                errors={errors}
              />
            )}
          </div>

          <hr className="my-4" />
          <div className="d-flex justify-content-end gap-2">
            {activeStep === SiteStepsEnum.SITE_INFORMATION ? (
              <>
                <ButtonCustom
                  type="button"
                  onClick={navigateToSites}
                  className="btn-secondary btn-lg"
                  context={t("general.buttons.cancel")}
                />
                <ButtonCustom
                  type="button"
                  onClick={handleNext}
                  className="btn-primary btn-lg"
                  context={t("general.buttons.next")}
                />
              </>
            ) : (
              <>
                <ButtonCustom
                  type="button"
                  onClick={() => setActiveStep(SiteStepsEnum.SITE_INFORMATION)}
                  className="btn-secondary btn-lg"
                  context={t("general.buttons.back")}
                />
                <ButtonCustom
                  type="button"
                  onClick={handleSubmit}
                  className=" btn-primary btn-lg"
                  context={
                    isEdit
                      ? t("general.buttons.save")
                      : t("sitePage.siteCreate.addSiteButton")
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
    </MainHome>
  );
};

export default SitesPageCreate;
