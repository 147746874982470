import React, { useState } from "react";
import { Col, Form, Button } from "react-bootstrap";
import "../../styles/loginStyles/login.scss";
import { Link, useNavigate } from "react-router-dom";
import HalfScreenLogo from "../../components/HalfScreenLogo";
import i18n from "../../i18n/i18n";
import { postLogin } from "../../common/api/apiCalls";
import { useUser } from "../../common/contexts/UserContext";
import { roles } from "../../common/consts/roles";
import closedEye from "../../common/assats/input/eye-off.svg";
import openEye from "../../common/assats/input/eye-open.svg";
import { FormErrors } from "../../common/types/formErrorType";
import { NavigationPaths } from "../../common/consts/navigationPaths";
function Login() {
  const { getUser } = useUser();
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [formData, setFormData] = useState({ email: '', password: '' });
  const validateForm = () => {
    const errors: FormErrors = {};
    if (!formData.email) errors.email = 'Email field is required';
    if (!formData.password) errors.password = 'Password field is required';
    return errors;
  };
  const SIGN_IN_TEXT_MAIN_TITLE = i18n.t("login.SIGN_IN_TEXT_MAIN_TITLE");
  const SIGN_IN_TEXT_SUB_TITLE = i18n.t("login.SIGN_IN_TEXT_SUB_TITLE");
  const SIGN_IN_TEXT_INPUT_1_PLACEHOLDER = i18n.t(
    "login.SIGN_IN_TEXT_INPUT_1_PLACEHOLDER"
  );
  const SIGN_IN_TEXT_INPUT_2_PLACEHOLDER = i18n.t(
    "login.SIGN_IN_TEXT_INPUT_2_PLACEHOLDER"
  );
  const SIGN_IN_TEXT_BUTTON_1 = i18n.t("login.SIGN_IN_TEXT_BUTTON_1");
  const SIGN_IN_TEXT_BUTTON_2 = i18n.t("login.SIGN_IN_TEXT_BUTTON_2");
  const SIGN_IN_TEXT_LABEL_1 = i18n.t("login.SIGN_IN_TEXT_LABEL_1");

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    // Validate form fields
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
  

    // Call API
    const res = await postLogin({
      username: formData.email,
      password: formData.password,
    });
    if (res.success) {
      let user = await getUser();
      console.log(user?.role)
      if(user && user.needToChangePassword){
        navigate("/Register/SetNewPassword", { replace: true });
      }
      else if (user && user?.role.includes(roles.SuperAdmin)){
        navigate(NavigationPaths.DASHBOARD, { replace: true });
      }else {
        navigate(NavigationPaths.PATIENTS, { replace: true })
      };
    }else if (res.error === 'User does not exist' || res.error === 'Wrong password') {
        setFormErrors({ password: 'Incorrect username or password' });
      }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  return (
    <HalfScreenLogo>
      <Col className="right-part d-flex align-items-center justify-content-center">
        <Form className="form-container" onSubmit={onSubmit}>
          <Form.Group
            className="title-group"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Form.Label className="global-big-label text-1o">
              {SIGN_IN_TEXT_MAIN_TITLE}
            </Form.Label>
            <Form.Label className="global-sub-label text-3o">
              {SIGN_IN_TEXT_SUB_TITLE}
            </Form.Label>
          </Form.Group>
          <Form.Group className="group-spacing" controlId="formBasicEmail">
            <Form.Control
            onChange={handleChange}
              className="global-input"
              type="email"
              name="email"
              placeholder={SIGN_IN_TEXT_INPUT_1_PLACEHOLDER}
            />
              <Form.Text className="text-danger" style={{ marginLeft: "20px" }}>
                {formErrors.email}
              </Form.Text>
          </Form.Group>
          <Form.Group className="group-spacing" controlId="formBasicPassword">
            <div className="password-container position-relative">
              <Form.Control
              onChange={handleChange}
                className="global-input"
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Enter your password"
              />
              <div
                className="password-icon position-absolute bg-white"
                onClick={() => handleTogglePassword()}
                style={{
                  right: "1px",
                  paddingRight: "19px",
                  paddingLeft:"20px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                {
                  <img
                    src={showPassword ? openEye : closedEye}
                    alt="pass-icon"
                  />
                }
              </div>
            </div>
            <Form.Text className="text-danger" style={{ marginLeft: "20px" }}>
                {formErrors.password}
              </Form.Text>
          </Form.Group>

          <Button
            className="global-button group-spacing"
            variant="primary"
            type="submit"
          >
            {SIGN_IN_TEXT_BUTTON_1}
          </Button>

          <Form.Group className="group-center">
            <Link to="/ForgetPassword">
              <Form.Label className="global-sub-label global-font-weight-600 text-primary cursor-pointer">
                {SIGN_IN_TEXT_LABEL_1}
              </Form.Label>
            </Link>
          </Form.Group>
        </Form>
      </Col>
    </HalfScreenLogo>
  );
}

export default Login;
