import React, { FC, useEffect, useMemo, useState } from 'react'
import "../../styles/homePage/table/tableHeader.scss"
import { useTranslation } from 'react-i18next'
import TableHeaderCards from './TableHeaderCards'
import { TableCard } from '../../common/types/tableCardType'
import { ReactComponent as Arrow } from '../../common/assats/homeScreens/cardsIcons/arrow.svg';

export interface TableHeaderParams {
    headerHeadline?: string
    totalCount?: number
    cards: TableCard[]
    viewAllAction?: () => void
};



const TableHeader: FC<TableHeaderParams> = ({ headerHeadline, cards, totalCount, viewAllAction }) => {

    const { t } = useTranslation()

    return (
        
        <div className='table-header-main-container'>
           {headerHeadline ? 
            <div className={`table-status-headline ${!totalCount ? 'flex-start' : ''}`}>
            <div className='main-headline-container'>
                <h2 className='main-headline'>{headerHeadline}</h2>
            </div>
            {totalCount ? 
             <div className='second-headline-container'>
             <h4 className='total-text'>{t("table.header.total") + " " + headerHeadline} {totalCount}</h4>
             <p className='view-all-text' onClick={viewAllAction}>{t("table.header.viewAll")} <Arrow /></p>

         </div> : null
            }
           
        </div> : null
        }
            <div className='patients-cards'>
                <TableHeaderCards cards={cards}/>
            </div>
        </div>
    )
}

export default TableHeader