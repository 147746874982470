import React, { FC } from "react";
import "../styles/loginStyles/halfScreenLogo.scss";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { logos } from "../common/assats";
// import { ReactComponent as Logo } from '../common/assats/logos/Frame4.svg';
interface HalfScreenLogo {
  children: React.ReactNode;
}
const HalfScreenLogo: FC<HalfScreenLogo> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Container fluid className="HalfScreenLogo-main-container">
      <Row className="full-width-height no-gutters">
        <Col className="left-side flex-center">
          <Col className="inner-left-side flex-center">
            <Col className="logo-wrapper">
              <Image width={"10%"} src={logos.coraLogo} />
            </Col>

            <Col className="children-wrapper flex-center">{children}</Col>

            <Col className="footer-wrapper ">
              <Col className="powered-by-wrapper">
                <Form.Label>{t("half_screen.POWERED_BY")}</Form.Label>
              </Col>
              <Col className="terms-and-privacy">
                <Link to="/terms">
                  <Form.Label className="global-sub-label global-font-weight-600 text-primary cursor-pointer">
                    {t("half_screen.TERMS")}
                  </Form.Label>
                </Link>
                <Link to="/privacy">
                  <Form.Label className="global-sub-label global-font-weight-600 text-primary cursor-pointer">
                    {t("half_screen.PRIVACY")}
                  </Form.Label>
                </Link>
              </Col>
            </Col>
          </Col>
        </Col>

        <Col className="right-side d-flex justify-center align-center mt-3">
          <div className="background-image"></div>
        </Col>
      </Row>
    </Container>
  );
};

export default HalfScreenLogo;
