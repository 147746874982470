import MainHome from '../../components/MainHome'
import SitesPage from '../../components/sites/SitesPage'

const Sites = () => {
    return (
        <MainHome>
            <SitesPage />
        </MainHome>
    )
}

export default Sites