import React, { useMemo, useState } from "react";
import TableHeader from "./genericTable/TableHeader";
import { TableCard } from "../common/types/tableCardType";
import { t } from "i18next";
import { ReactComponent as DeviceIcon } from "../common/assats/homeScreens/cardsIcons/devices.svg";
import { ReactComponent as ActiveDeviceIcon } from "../common/assats/homeScreens/cardsIcons/activeDevices.svg";
import { ReactComponent as ExamsIcon } from "../common/assats/homeScreens/cardsIcons/exams.svg";
import TableFilters from "./genericTable/TableFilters";
import GraphsViewer from "./dashboard/GraphsViewer";
import "../styles/components/DashboardPage.scss";
import { useQuery } from "@tanstack/react-query";
import { getAllBranchPatientsWithClinical } from "../common/api/apiCalls";

const DashboardPage = () => {
  const [sortKey, setSortKey] = useState<string>("patientName");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const cards: TableCard[] = [
    {
      headline: "Total Devices",
      explanation: t("patientsList.cards.BeforeExamExplain"),
      icon: DeviceIcon,
      value: 105,
      action: () => {
        console.log("click");
      },
    },
    {
      headline: "Total Active Devices",
      explanation: t("patientsList.cards.afterExamExplain"),
      icon: ActiveDeviceIcon,
      value: 55,
      action: () => {
        console.log("click");
      },
    },
    {
      headline: t("Total Exam"),
      explanation: t("patientsList.cards.afterConsultationExplain"),
      icon: ExamsIcon,
      value: 280,
      action: () => {
        console.log("click");
      },
    },
  ];

  const mockData: Array<{ [key: string]: string | boolean | number }> = [
    {
      smokingStatus: "> 5 years cessation",
      gender: "Male",
      race: "Caucasian",
      isSymptomatic: true,
      weight: 82,
      height: 170,
    }, // BMI ≈ 28.4
    {
      smokingStatus: "No",
      gender: "Female",
      race: "Asian",
      isSymptomatic: false,
      weight: 55,
      height: 157,
    }, // BMI ≈ 22.3
    {
      smokingStatus: "< 5 years cessation",
      gender: "Female",
      race: "Hispanic",
      isSymptomatic: true,
      weight: 75,
      height: 160,
    }, // BMI ≈ 29.3
    {
      smokingStatus: "< 1 year cessation",
      gender: "Male",
      race: "Hispanic",
      isSymptomatic: false,
      weight: 68,
      height: 175,
    }, // BMI ≈ 22.2
    {
      smokingStatus: "No",
      gender: "Non-binary",
      race: "Caucasian",
      isSymptomatic: true,
      weight: 73,
      height: 165,
    }, // BMI ≈ 26.8
    {
      smokingStatus: "< 5 years cessation",
      gender: "Male",
      race: "Asian",
      isSymptomatic: false,
      weight: 62,
      height: 180,
    }, // BMI ≈ 19.1
    {
      smokingStatus: "> 5 years cessation",
      gender: "Female",
      race: "Hispanic",
      isSymptomatic: true,
      weight: 90,
      height: 155,
    }, // BMI ≈ 37.4
    {
      smokingStatus: "< 1 year cessation",
      gender: "Female",
      race: "Hispanic",
      isSymptomatic: false,
      weight: 65,
      height: 160,
    }, // BMI ≈ 25.4
    {
      smokingStatus: "No",
      gender: "Male",
      race: "Asian",
      isSymptomatic: true,
      weight: 75,
      height: 178,
    }, // BMI ≈ 23.7
    {
      smokingStatus: "> 5 years cessation",
      gender: "Female",
      race: "Pacific Islander",
      isSymptomatic: false,
      weight: 69,
      height: 160,
    }, // BMI ≈ 27.0
    {
      smokingStatus: "< 5 years cessation",
      gender: "Non-binary",
      race: "Hispanic",
      isSymptomatic: true,
      weight: 85,
      height: 170,
    }, // BMI ≈ 29.4
    {
      smokingStatus: "< 1 year cessation",
      gender: "Male",
      race: "Native American",
      isSymptomatic: false,
      weight: 80,
      height: 173,
    }, // BMI ≈ 26.7
    {
      smokingStatus: "> 5 years cessation",
      gender: "Female",
      race: "Hispanic",
      isSymptomatic: true,
      weight: 77,
      height: 162,
    }, // BMI ≈ 29.4
    {
      smokingStatus: "< 1 year cessation",
      gender: "Female",
      race: "Caucasian",
      isSymptomatic: false,
      weight: 60,
      height: 165,
    }, // BMI ≈ 22.0
    {
      smokingStatus: "No",
      gender: "Male",
      race: "Asian",
      isSymptomatic: true,
      weight: 72,
      height: 177,
    }, // BMI ≈ 23.0
    {
      smokingStatus: "< 5 years cessation",
      gender: "Female",
      race: "Asian",
      isSymptomatic: false,
      weight: 58,
      height: 160,
    }, // BMI ≈ 22.7
    {
      smokingStatus: "> 5 years cessation",
      gender: "Non-binary",
      race: "Pacific Islander",
      isSymptomatic: true,
      weight: 90,
      height: 165,
    }, // BMI ≈ 33.1
    {
      smokingStatus: "No",
      gender: "Female",
      race: "Native American",
      isSymptomatic: false,
      weight: 68,
      height: 160,
    }, // BMI ≈ 26.6
    {
      smokingStatus: "< 5 years cessation",
      gender: "Male",
      race: "Hispanic",
      isSymptomatic: true,
      weight: 85,
      height: 172,
    }, // BMI ≈ 28.7
    {
      smokingStatus: "< 1 year cessation",
      gender: "Non-binary",
      race: "Hispanic",
      isSymptomatic: false,
      weight: 92,
      height: 173,
    }, // BMI ≈ 30.7
    {
      smokingStatus: "> 5 years cessation",
      gender: "Female",
      race: "Caucasian",
      isSymptomatic: true,
      weight: 68,
      height: 162,
    }, // BMI ≈ 25.9
    {
      smokingStatus: "No",
      gender: "Male",
      race: "Asian",
      isSymptomatic: false,
      weight: 60,
      height: 175,
    }, // BMI ≈ 19.6
    {
      smokingStatus: "< 5 years cessation",
      gender: "Female",
      race: "Asian",
      isSymptomatic: true,
      weight: 70,
      height: 160,
    }, // BMI ≈ 27.3
  ];

  const calcBMI = (heightCm: number, weightKg: number): number => {
    const heightM = heightCm / 100;
    return weightKg / (heightM * heightM);
  };

  const cardsConfig: Array<{
    property: string;
    type: "circle" | "bar";
    title: string;
    barCount?: number;
  }> = [
    { property: "smokingStatus", type: "circle", title: "Smoking" },
    { property: "race", type: "circle", title: "Race/Ethnicity" },
    { property: "isSymptomatic", type: "circle", title: "Symptomatic" },
    { property: "gender", type: "circle", title: "Gender" },
    // { property: "newBMI", type: "bar", title: "BMI", barCount: 5 },
    // { property: "newBMI", type: "bar", title: "BMI", barCount: 3 },
  ];

  const { data, isPending, error } = useQuery({
    queryKey: ["statsData"],
    queryFn: () => getAllBranchPatientsWithClinical(sortKey, sortOrder),
  });

  const dataToShow = useMemo(() => {
    return data?.patients.map((person: any) => {
      //data?.patients

      const { height, weight } = person;
      const BMI = calcBMI(height as number, weight as number);

      return {
        ...person,
        newBMI: parseInt(BMI.toFixed(1)), // Rounds BMI to one decimal point
      };
    });
  }, [data, isPending]);
  console.log(window.devicePixelRatio);
  return (
    <div className="dashboard-page-main-container">
      <div className="table-header">
        <TableHeader cards={cards} />
      </div>
      <div className="dashboard-filters">
        <TableFilters />
      </div>

      <GraphsViewer dataSet={mockData ?? []} cardsConfig={cardsConfig} />
    </div>
  );
};

export default DashboardPage;
