import React from 'react';
import "../../styles/homePage/table/tableRow.scss";
import { Form } from 'react-bootstrap';
import { TableRowBase, TableRowProps } from '../../types/Table.interface';
import Settings from '../../pages/home/Settings';
import { ReactComponent as SettingsIcon } from "../../common/assats/table/settings.svg";



const TableRow = <T extends TableRowBase>(props: TableRowProps<T>) => {

  const handleChange = (e: any) => {
    e.preventDefault();
    checkBoxClick && checkBoxClick(row);
  }

    const {onRowClick, checkBoxClick, row, ...restProps} = props;
    const keys = Object.keys(props.row).filter((key) => key !== "tableProps");
    
    return (
        <div className='table-row-main-container'>
            <Form.Check inline checked={row.tableProps?.selected} {...restProps} onChange={handleChange}/>
            {keys.map((key, index) => (
                <div key={index + key.toLowerCase()} className={`table-cell`}>
                    <span className={`${key.includes("Status") ? "rounded-status" : ""}`}>
                        {(row as any)[key] ?? "N/A"}
                    </span>
                </div>
            ))}
            <div className={`settings`}>
                <SettingsIcon className='settings-icon' onClick={() => onRowClick && onRowClick(row)}/>
            </div>
        </div>
    );
};

export default TableRow;
