import React, { useEffect, useState } from "react";
import "../../../styles/components/Filter.scss";
import { ReactComponent as FiltersIcon } from "../../../common/assats/table/FiltersIcon.svg";
import { ReactComponent as CloseIcon } from "../../../common/assats/icons/filterXCloseIcon.svg";
import { ReactComponent as ArrowIcon } from "../../../common/assats/icons/arrowIcon.svg";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFilters } from "../../../common/contexts/FiltersContext";

interface FilterOptionInterface {
  [key: string]: {
    label: string;
    options: { name: string; label: string }[];
  };
}

interface FilterProps {
  filtersObject?: FilterOptionInterface;
  handleFilterClose?: () => void;
  isOpen?: boolean;
}

// DEV ONLY
const tempFiltersObject: FilterOptionInterface = {
  status: {
    label: "Status",
    options: [
      { name: "before", label: "Before Exam" },
      { name: "after", label: "After Exam" },
    ],
  },
};

const Filter: React.FC<FilterProps> = ({
  filtersObject = tempFiltersObject,
  handleFilterClose,
  isOpen = false,
}) => {
  const [currentFilter, setCurrentFilter] = useState<
    keyof FilterOptionInterface | null
  >(null);

  const { t } = useTranslation();
  const { activeFilters, filtersCount, handleFilters, clearFilters } = useFilters();

  const handleOptionClick = (
    filterName: string,
    optionName: string,
    isSelected: boolean
  ) => {
    handleFilters(filterName, optionName, isSelected);
  };

  const clearFiltersHandler = () => {
    clearFilters();
  };

  return (
    <div className={`custom-filter-container ${isOpen ? "open" : ""}`}>
      <div className="filters-header">
        <div className="header-title">
          {currentFilter ? (
            <ArrowIcon
              style={{ transform: "rotate(180deg)", cursor: "pointer" }}
              onClick={() => setCurrentFilter(null)}
            />
          ) : (
            <FiltersIcon />
          )}
          {currentFilter ? filtersObject[currentFilter].label : "Filter"}
        </div>
        <div>
          <CloseIcon
            onClick={handleFilterClose}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
      <div className="filter-options-container">
        {!currentFilter ? (
          <div className="filter-options">
            {Object.keys(filtersObject).map((filterName) => (
              <div
                className="filter-option"
                key={`${filterName}filterOption`}
                onClick={() => setCurrentFilter(filterName)}
              >
                <div className="option-label">
                  {filtersObject[filterName].label}
                </div>
                <div>
                  <ArrowIcon />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="embedded-filter-options">
            {filtersObject[currentFilter].options.map((filterOption) => (
              <div
                className="filter-option"
                key={`${filterOption.name}embeddedfilterOption`}
              >
                <Form.Check
                  inline
                  id={`${filterOption.name}-radio`}
                  checked={
                    activeFilters[currentFilter]?.includes(filterOption.name) ||
                    false
                  } // Check if this option is selected
                  onChange={(e) => {
                    const isSelected = e.target.checked;
                    handleOptionClick(
                      currentFilter as string,
                      filterOption.name,
                      isSelected
                    );
                  }}
                />
                <label htmlFor={`${filterOption.name}-radio`}>
                  {filterOption.label}
                </label>
              </div>
            ))}
          </div>
        )}
        <hr />
        <div className="filter-footer d-flex align-items-center justify-content-start mb-3 ms-3">
          <span>
            {filtersCount} {t("general.filters.title")}
          </span>
          {filtersCount ? (
            <span onClick={clearFiltersHandler} className="link ms-auto me-3">
              {t("general.filters.clear")}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Filter;