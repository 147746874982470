import React, { useEffect } from "react";
import "./App.css";
import "./styles/app.scss";
import { Route, Routes } from "react-router-dom";
import SignIn from "./pages/register/SignIn";
import "bootstrap/dist/css/bootstrap.min.css";
import ForgetPassword from "./pages/loginScreens/ForgetPassword";
import PersonalInfo from "./pages/register/PersonalInfo";
import ResetPassword from "./pages/loginScreens/ResetPassword";
import SetNewPassword from "./pages/register/SetNewPassword";
import Login from "./pages/loginScreens/Login";
import Success from "./components/Success";
import { useScrollBlock } from "./common/hooks/DisableScroll";
import { UserContextProvider } from "./common/contexts/UserContext";
import NotAuthorized from "./pages/NotAuthorized";
import PatientsList from "./pages/home/PatientsList";
import Patient from "./components/Patient/Patient";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PrivateRoute from "./auth/PriveteRoute";
import About from "./pages/home/About";
import Support from "./pages/home/Support";
import Contact from "./pages/home/Contact";
import Settings from "./pages/home/Settings";
import Dashboard from "./pages/home/Dashboard";
import Sites from "./pages/home/Sites";
import Devices from "./pages/home/Devices";
import Fota from "./pages/home/Fota";
import UsersList from "./pages/home/UsersList";
import ToastProvider from "./common/contexts/ToastProvider";
import SitesPageCreate from "./components/sites/SiteCreate";
import AddPatient from "./components/patientsList/addPatient/AddPatient";
import UserCreatePage from "./components/usersList/usersCreate";
import GeneralInformation from "./components/common/information/information";
import { FiltersProvider } from "./common/contexts/FiltersContext";

const queryClient = new QueryClient();
function App() {
  const [blockScroll, allowScroll] = useScrollBlock();
  useEffect(() => {
    blockScroll();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <ToastProvider>
          <FiltersProvider>
            <main className="full-width-height app-main">
              <Routes>
                <Route path={"Success/:type"} Component={Success} />
                <Route path={"NotAuthorized"} Component={NotAuthorized} />

                <Route path={"Register"}>
                  <Route path={"SignIn/:token"} Component={SignIn} />
                  <Route path={"PersonalInfo"} Component={PersonalInfo} />
                  <Route path={"SetNewPassword"} Component={SetNewPassword} />
                </Route>

                <Route path={"/"} Component={Login} />
                <Route path={"ResetPassword"} Component={ResetPassword} />
                <Route path={"ForgetPassword"} Component={ForgetPassword} />

                <Route path={"Home"}>
                  <Route
                    path={"Patients/Patient/:patientId"}
                    element={
                      <PrivateRoute
                        component={Patient}
                        componentName="Patient"
                      />
                    }
                  />
                  <Route
                    path={"Patients/Patient/Create"}
                    element={
                      <PrivateRoute
                        component={AddPatient}
                        componentName="Patient"
                      />
                    }
                  />
                  <Route
                    path={"Patients/Patient/:patientId/Edit"}
                    element={
                      <PrivateRoute
                        component={AddPatient}
                        componentName="Patient"
                      />
                    }
                  />
                  <Route
                    path={"About"}
                    element={
                      <PrivateRoute component={About} componentName="About" />
                    }
                  />
                  <Route
                    path={"Support"}
                    element={
                      <PrivateRoute
                        component={Support}
                        componentName="Support"
                      />
                    }
                  />
                  <Route
                    path={"Contact"}
                    element={
                      <PrivateRoute
                        component={Contact}
                        componentName="Contact"
                      />
                    }
                  />
                  <Route
                    path={"Settings"}
                    element={
                      <PrivateRoute
                        component={Settings}
                        componentName="Settings"
                      />
                    }
                  />
                  <Route
                    path={"Dashboard"}
                    element={
                      <PrivateRoute
                        component={Dashboard}
                        componentName="Dashboard"
                      />
                    }
                  />
                  <Route
                    path={"Patients"}
                    element={
                      <PrivateRoute
                        component={PatientsList}
                        componentName="PatientsList"
                      />
                    }
                  />
                  <Route
                    path={"Sites"}
                    element={
                      <PrivateRoute component={Sites} componentName="Sites" />
                    }
                  />
                  <Route
                    path={"Sites/Create"}
                    element={
                      <PrivateRoute
                        component={SitesPageCreate}
                        componentName="SitesPageCreate"
                      />
                    }
                  />
                  <Route
                    path={"Sites/view/:id"}
                    element={
                      <PrivateRoute
                        component={GeneralInformation}
                        componentName="SitesPageCreate"
                      />
                    }
                  />
                  <Route
                    path={"Sites/Edit/:id"}
                    element={
                      <PrivateRoute
                        component={SitesPageCreate}
                        componentName="SitesPageCreate"
                      />
                    }
                  />
                  <Route
                    path={"Users"}
                    element={
                      <PrivateRoute
                        component={UsersList}
                        componentName="UsersList"
                      />
                    }
                  />
                  <Route
                    path={"Users/Create"}
                    element={
                      <PrivateRoute
                        component={UserCreatePage}
                        componentName="UsersCreate"
                      />
                    }
                  />
                  <Route
                    path={"Users/View/:id"}
                    element={
                      <PrivateRoute
                        component={GeneralInformation}
                        componentName="UsersCreate"
                      />
                    }
                  />
                  <Route
                    path={"Users/Edit/:id"}
                    element={
                      <PrivateRoute
                        component={UserCreatePage}
                        componentName="UsersCreate"
                      />
                    }
                  />
                  <Route
                    path={"Devices"}
                    element={
                      <PrivateRoute
                        component={Devices}
                        componentName="Devices"
                      />
                    }
                  />
                  <Route
                    path={"Fota"}
                    element={
                      <PrivateRoute component={Fota} componentName="Fota" />
                    }
                  />
                </Route>
              </Routes>
            </main>
          </FiltersProvider>
        </ToastProvider>
      </UserContextProvider>
    </QueryClientProvider>
  );
}

export default App;
