
import { useTranslation } from "react-i18next";
import "../../styles/homePage/genericForm.scss"
import PropertySegment from "./general/PropertySegment";

export interface InfoFieldsBuilderInterface {
  title: string;
  textArray: string[];
  offset?: string;
  className?: string;
  translateOffset?: boolean;
  colWidth?: string;
  nested?: number;
  fullRow?: boolean;
  translate?: boolean;
}

interface InfoFieldsBuilderProps {
  fields: InfoFieldsBuilderInterface[];
  containerClassName?: string;
}

const InfoFieldsBuilder = (props: InfoFieldsBuilderProps) => {
  const { fields, containerClassName } = props;
  const { t } = useTranslation();

  return (
    <div className="generic-form">
      <div className={`row mb-5 generic-form-container ${containerClassName}`}>
        {fields.map((field, i) => (
           <div
           className={` ${field.nested ? "has-nested" : ""} ${
             field.offset ? `offset-${field.offset}` : ""
           } ${field.fullRow ? "col-sm-12" : field.colWidth ?? "col-sm-6"} ${
             field.translateOffset ? "translate-offset" : ""
           } mb-2`}
            key={`${field.title}${i}`}
          >
            <PropertySegment
              title={field?.translate ? t(field.title) : field.title}
              textArray={field.textArray}
              className={`${field.offset ? `offset-${field.offset}` : ''} ${
                field.translateOffset ? 'translate-offset' : ''
              }`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoFieldsBuilder;
