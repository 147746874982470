import React, { FC, useEffect, useState } from "react";
import "../../styles/homePage/table/table.scss";
import TableFilters, { TableFiltersProps } from "./TableFilters";
import TableRow from "./TableRow";
import SortTable, { SortTableProps } from "./SortTable";
import { TableProps, TableRowBase } from "../../types/Table.interface";

const Table = <T extends TableRowBase>(
  props: TableProps<T> & TableFiltersProps & Partial<SortTableProps>
) => {
  const {
    tableHeadline,
    trashAction,
    filterAction,
    searchValue,
    setSearchValue,
    searchPlaceholder,
    buttonText,
    buttonAction,
    tableData,
    setSortKey,
    sortKey,
    sortKeysArray,
    sortOrder,
    setSortOrder,
    onRowClick,
    onCheckBoxClick,
    ...restProps
  } = props;

  const [tableDataState, setTableDataState] = useState(tableData);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setTableDataState(tableData);
  }, [tableData]);

  const handleCheckboxClick = (row: any) => {
    const updatedTableData = tableDataState.map((val) => {
      if (val?.tableProps?.id === row?.tableProps?.id) { 
        return {
          ...val,
          tableProps: { 
            ...val.tableProps, 
            selected: !val.tableProps?.selected,
          },
        };
      }
      return val;
      
    });
  
    setTableDataState(updatedTableData);
    onCheckBoxClick && onCheckBoxClick(updatedTableData.filter((val) => val.tableProps.selected));
  };

  const handleCheckAll = () => {
    const updatedTableData = tableDataState.map((val) => {
      return {
        ...val,
        tableProps: {
          ...val.tableProps,
          selected: !selectAll,
        },
      };
    });
    setSelectAll(!selectAll);
    setTableDataState(updatedTableData);
    onCheckBoxClick && onCheckBoxClick(updatedTableData.filter((val) => val.tableProps.selected));
  }

  return (
    <div className="table-main-container">
      <div className="filters-container">
        <TableFilters
          tableHeadline={tableHeadline}
          trashAction={trashAction}
          filterAction={filterAction}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          searchPlaceholder={searchPlaceholder}
          buttonText={buttonText}
          buttonAction={buttonAction}
        />
      </div>
      <div className="sort-wrapper">
        <SortTable
          setSortOrder={setSortOrder!}
          sortOrder={sortOrder!}
          sortKeysArray={sortKeysArray!}
          setSortKey={setSortKey!}
          sortKey={sortKey!}
          handleCheckAll={handleCheckAll}
        />
      </div>
      <div className="table-wrapper pb-3">
        {tableDataState.map((val, index) => (
          <TableRow
            row={val}
            key={`${index + Date.now()}`}
            checkBoxClick={(row) => {
              handleCheckboxClick(row);
            }}
            {...restProps}
            onRowClick={(row) => onRowClick && onRowClick(row)}
          />
        ))}
      </div>
    </div>
  );
};

export default Table;
