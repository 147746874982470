import React, { useState } from 'react'
import { Col, Form, Button } from 'react-bootstrap';
import '../../styles/loginStyles/setNewPassword.scss';

import { Link, useNavigate } from 'react-router-dom';
import HalfScreenLogo from '../../components/HalfScreenLogo';
import i18n from "../../i18n/i18n"
import { postSetFirstPassword } from '../../common/api/apiCalls';
import { useTranslation } from 'react-i18next';
const prefix = "set_new_password"
const SetNewPassword = () => {
    const { t } = useTranslation()
    const [password, setPassword] = useState("")
    const [capitalLetter, setCapitalLetter] = useState(false)
    const [specialChar, setSpecialChar] = useState(false)
    const [number, setNumber] = useState(false)


    const navigate = useNavigate();
    const SIGN_IN_TEXT_MAIN_TITLE = i18n.t('set_new_password.SIGN_IN_TEXT_MAIN_TITLE');
    const SIGN_IN_TEXT_SUB_TITLE = i18n.t('set_new_password.SIGN_IN_TEXT_SUB_TITLE');
    const SIGN_IN_TEXT_INPUT_1_PLACEHOLDER = i18n.t('set_new_password.SIGN_IN_TEXT_INPUT_1_PLACEHOLDER');
    const SIGN_IN_TEXT_INPUT_2_PLACEHOLDER = i18n.t('set_new_password.SIGN_IN_TEXT_INPUT_2_PLACEHOLDER');
    const SIGN_IN_TEXT_BUTTON_1 = i18n.t('set_new_password.SIGN_IN_TEXT_BUTTON_1');
    const SIGN_IN_TEXT_BUTTON_2 = i18n.t('set_new_password.SIGN_IN_TEXT_BUTTON_2');
    const SIGN_IN_TEXT_LABEL_1 = i18n.t('set_new_password.SIGN_IN_TEXT_LABEL_1');

    function testPassword(text: string) {
        const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/g;
        const numberPattern = /\d/;
        const capitalLetterPattern = /[A-Z]/;
        setCapitalLetter(capitalLetterPattern.test(text));
        setSpecialChar(specialCharPattern.test(text));
        setNumber(numberPattern.test(text));
    }
    const onSubmit = async (event: any) => {
        event.preventDefault();
        const res = await postSetFirstPassword({ password: password as string })
        if (res.success)
            navigate("/Success/password")

    };

    return (
        <HalfScreenLogo>
            <Col className="right-part d-flex align-items-center justify-content-center">
                <Form className="form-container" onSubmit={onSubmit}>
                    <Form.Group className='title-group' style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label className="global-big-label text-1o">{SIGN_IN_TEXT_MAIN_TITLE}</Form.Label>
                        <Form.Label className='global-sub-label text-3o'>{SIGN_IN_TEXT_SUB_TITLE}</Form.Label>
                    </Form.Group>
                    <Form.Group className='' controlId="formBasicEmail">
                        <Form.Control value={password} onChange={(e) => { setPassword(e.target.value); testPassword(e.target.value) }} className='global-input' type="password" placeholder={SIGN_IN_TEXT_INPUT_1_PLACEHOLDER} />
                    </Form.Group>
                    <Form.Group className='password-instructions-group' style={{ display: 'flex', flexDirection: 'column' }}>

                        <Form.Text className='password-instructions' >
                            {t(prefix + ".Password_must_be_at_least")}
                            <Form.Text className={`reqText ${password.length >= 8 ? "req-done-color" : "req-not-done-color"}`}>
                                {" " + t(prefix + ".8_Characters")}
                            </Form.Text>
                            {" " + t(prefix + ".and_must_contain_at_least_a")}
                        </Form.Text>

                        <Form.Text className='password-instructions'>
                            <Form.Text className={`reqText ${capitalLetter ? "req-done-color" : "req-not-done-color"} `}>
                                {" " + t(prefix + ".Capital_Letter")}
                            </Form.Text>
                            {" " + t(prefix + ".a")}
                            <Form.Text className={`reqText ${number ? "req-done-color" : "req-not-done-color"}`}>
                                {" " + t(prefix + ".Number")}
                            </Form.Text>
                            {" " + t(prefix + ".and_a")}
                            <Form.Text className={`reqText ${specialChar ? "req-done-color" : "req-not-done-color"}`}>
                                {" " + t(prefix + ".Special_Character")}
                            </Form.Text>

                        </Form.Text>
                    </Form.Group>
                    <Form.Group className='group-spacing' controlId="formBasicPassword">
                        <Form.Control className='global-input' type="password" placeholder={SIGN_IN_TEXT_INPUT_2_PLACEHOLDER} />
                    </Form.Group>

                    <Form.Group className='checkbox-wrapper checkbox-wrapper' >
                        <Form.Check inline />
                        <Form.Label className='global-sub-label global-font-weight-600'>{SIGN_IN_TEXT_BUTTON_2}</Form.Label>
                        <Link to='/ForgetPassword'>
                            <Form.Label className='global-sub-label global-font-weight-600 text-primary cursor-pointer space underline'>{SIGN_IN_TEXT_LABEL_1}</Form.Label>
                        </Link>
                    </Form.Group>
                    <Button className='global-button group-spacing' variant="primary" type="submit">
                        {SIGN_IN_TEXT_BUTTON_1}
                    </Button>

                </Form>
            </Col>
        </HalfScreenLogo >
    );
}

export default SetNewPassword

{/* <span className='password-instructions' >
{t(prefix + ".Password_must_be_at_least")}
<span>
    {" " + t(prefix + ".8_Characters")}
</span>
{" " + t(prefix + ".and_must_contain_at_least_a")}
<span>
    {" " + t(prefix + ".Capital_Letter")}
</span>
{" " + t(prefix + ".a")}
<span>
    {" " + t(prefix + ".Number")}
</span>
{" " + t(prefix + ".and_a")}
<span>
    {" " + t(prefix + ".Special_Character")}
</span>

</span> */}