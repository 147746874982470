import {SitePropsGeneralInformation } from "../../common/interfaces/Sites.interface";
import { InfoFieldsBuilderInterface } from "../common/InfoFieldsBuilder";

export const siteInfoFields = (
  data: SitePropsGeneralInformation
): InfoFieldsBuilderInterface[] => [
  {
    title: "sitePage.siteCreate.superUserFullName",
    textArray: [data?.superUserFullName ?? ""],
    colWidth: "col-sm-3",
    translate: true,
  },
  {
    title: "sitePage.siteCreate.superUserEmail",
    textArray: [data?.superUserEmail ?? ""],
    colWidth: "col-sm-3",
    translate: true,
  },
  {
    title: "sitePage.siteCreate.superUserPhone",
    textArray: [data?.superUserPhone ?? ""],
    colWidth: "col-sm-3",
    translate: true,
  },
  {
    title: "sitePage.siteCreate.metrics",
    textArray: [data?.metrics ?? ""],
    colWidth: "col-sm-3",
    translate: true,
  },
  {
    title: "sitePage.siteCreate.address",
    textArray: [data?.address ?? ""],
    colWidth: "col-sm-3",
    translate: true,
  }
];
