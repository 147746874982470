import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../styles/components/RadioToggleButton.scss"; // External CSS for styling (explained below)
import { ClinicalInfoInterface } from "../../../types/PatientData.interface";

interface RadioToggleButtonProps {
  optionsArray: string[]; // Array of options (e.g., ["Acute", "Chronic"])
  selectedOption: string; // The currently selected option
  disabled?: boolean
  handleInputChange: React.ChangeEventHandler<
  HTMLInputElement | HTMLSelectElement | HTMLOptionElement
>
  name: string
}

const RadioToggleButton: React.FC<RadioToggleButtonProps> = ({
  optionsArray,
  selectedOption,
  name,
  disabled,
  handleInputChange,
}) => {
  return (
    <div className="btn-group btn-group-toggle">
      {optionsArray.map((option, i) => (
        <label
          key={`${option}${i}`}
          className={`btn btn-outline-primary btn-radio ${
            selectedOption === option ? "active" : ""
          }`}
        >
          <input
            disabled={disabled}
            type="radio"
            name={name}
            value={option}
            checked={selectedOption === option}
            onChange={handleInputChange}
          />
          {option}
        </label>
      ))}
    </div>
  );
};

export default RadioToggleButton;
