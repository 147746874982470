import React from "react";
import { Card, Button, Col, Row } from "react-bootstrap";
import "../../../styles/components/GenericCards.scss";

export interface CardConfigInterface {
  title: string;
  body?: string;
  image?: string;
  footer?: string;
  buttonText?: string;
  buttonAction?: () => void;
}

interface GenericCardsProps {
  cardsConfig: CardConfigInterface[];
}

const GenericCards = ({ cardsConfig }: GenericCardsProps) => {
  return (
    <div className="generic-cards-scrollable-container ">
      <Row xs={1} md={3} lg={4} className="g-4">
        {cardsConfig.map((card, index) => (
          <Col key={index} className="d-flex justify-content-center">
            <Card className="custom-card">
              {card.image && <Card.Img variant="top" src={card.image} />}
              <Card.Body>
                <Card.Title>{card.title}</Card.Title>
                {card.body && <Card.Text>{card.body}</Card.Text>}
                {card.buttonText && card.buttonAction && (
                  <Button variant="primary" onClick={card.buttonAction}>
                    {card.buttonText}
                  </Button>
                )}
              </Card.Body>
              {card.footer && <Card.Footer>{card.footer}</Card.Footer>}
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default GenericCards;
