import React from 'react'
import MainHome from '../../components/MainHome'

const Settings = () => {
    return (
        <MainHome>
            <div>Settings</div>
        </MainHome>
    )
}

export default Settings