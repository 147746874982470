import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import MainHome from "../../MainHome";
import InfoFieldsBuilder, {
  InfoFieldsBuilderInterface,
} from "../InfoFieldsBuilder";
import "../../../styles/components/generalInformation.scss";

type InformationDataType = {
  displayData: InfoFieldsBuilderInterface[];
  editState: any;
  informationData: {
    title: string;
    status: string;
    textArray: string[];
    icon?: string;
    pathToEdit: string;
  };
};

const GeneralInformation: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  
  const locationState = location.state as InformationDataType | null;

  const informationData = locationState?.informationData;
  const data = locationState?.displayData;

  if (!informationData || !data) {
    return null;
  }

  const editOnClick = () => {
    navigate(informationData.pathToEdit, { state: locationState.editState });
  }

  return (
    <MainHome>
      <div className="general-container">
        <div className="general-header">
          <div className="d-flex">
            <div className="col-4">
              <div className="d-flex flex-row align-items-end">
                <h1 className="pe-3">{informationData.title}</h1>
                { <span className="pb-3" onClick={editOnClick}>
                    {t("general.edit")}
                  </span>}
              </div>
              <div className="min-content">
                <div className="rounded-status">{informationData.status}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="information-content">
          <InfoFieldsBuilder fields={data} />
        </div>
      </div>
    </MainHome>
  );
};

export default GeneralInformation;
