import React from 'react';
import '../styles/homePage/avatar.scss';

interface AvatarProps {
    initials: string;
}

const Avatar: React.FC<AvatarProps> = ({ initials }) => {
    return (
        <div className="avatar">
            <div className="inner-avatar">
                {initials}
            </div>
        </div>
    );
};

export default Avatar;