import axios, { AxiosError } from 'axios';

import { makeGetRequest, makePostRequest, urlsPath } from "./restServices"
import { CreatePatientClinicalInterface, CreatePatientInterface, FirstLoginBodyType, PostLoginType, PostOTPType, PostPersonalInfoType, PostSetNewPasswordType } from "./apiBodyTypes"
import { getErrorMessage } from './apiErrorHandler';
import { BaseFetchOptions } from '../types/fetch.type';
import { UserCreateInterface } from '../interfaces/usersData.interface';
import { SiteProps, SitePropsWithOptionalEmail } from '../interfaces/Sites.interface';

// let otpToken: string | null
let token: string | undefined

async function postLogin(body: PostLoginType): Promise<{ success: boolean, data: {}, error?: string | undefined }> {
    try {
        const res = await makePostRequest("/users/Login", body)
        if (!res.data.success)
            throw new Error(res.data.error)
        return { success: true, data: {} }

    } catch (err) {
        let errorMessage = "An unknown error occurred";

        if (axios.isAxiosError(err)) {
            // err is an AxiosError here
            errorMessage = err.response?.data?.error || err.message;
        } else if (err instanceof Error) {
            // If it's a regular Error
            errorMessage = err.message;
        }

        return { success: false, data: {}, error: errorMessage };
    }
}

// async function postOTP(body: PostOTPType): Promise<{ success: boolean, data: {} }> {
//     try {
//         const res = await axios({
//             method: 'post',
//             url: `${urlsPath}/users/validate-otp`,
//             data: { ...body, otpToken },
//             responseType: 'json',
//             withCredentials: true,
//         })
//         //catch if token expired. if so refresh token
//         if (res.data.success)
//             return { success: true, data: {} }
//         else
//             return { success: false, data: {} }

//     } catch (err) {
//         console.log("err", err)
//         return { success: false, data: {} }
//     }
// }

// async function postFirstLogin(body: FirstLoginBodyType, token: string): Promise<{ status: "success" | "failed", data: {} }> {
//     try {
//         const res = await axios({
//             method: 'post',
//             url: `${urlsPath}/users/first-login`,
//             data: body,
//             responseType: 'json',
//             headers: {
//                 Authorization: token,
//             },
//         })
//         //catch if token expired. if so refresh token
//         return { status: "success", data: {} }

//     } catch (err) {
//         console.log("err", err)
//         return { status: "failed", data: {} }
//     }
// }



async function postPersonalInfo(body: PostPersonalInfoType): Promise<{ success: boolean, data: {} }> {
    try {

        const res = await makePostRequest("/user-info/set-user-info", body)
        if (res.data.success)
            token = res.data.data.access_token
        return { success: true, data: {} }

    } catch (err) {
        console.log("err", err)
        return { success: false, data: {} }
    }
}

async function postSetFirstPassword(body: PostSetNewPasswordType): Promise<{ success: boolean, data: {} }> {
    try {
        // const res1 = await axios({
        //     method: 'post',
        //     url: `${urlsPath}/users/first-login-change-password`,
        //     data: body,
        //     responseType: 'json',
        //     headers: {
        //         Authorization: token,
        //     },
        // })
        const res = await makePostRequest("/users/first-login-change-password", body)
        console.log('res:', res)
        //catch if token expired. if so refresh token
        return { success: true, data: {} }

    } catch (err) {
        console.log("err", err)
        return { success: false, data: {} }
    }
}

async function getUserInfo() {
    try {
        const user = await makeGetRequest("users/get-user-by-token")
        return user.data
    }
    catch (err) {
        console.log("err", err)
        return { success: false, data: {} }
    }
}

async function getUserByEmailForSite(userName: string) {
    try {
        const user = await makeGetRequest(`users/get-user-site`, { userName });
        return user.data
    }
    catch (err) {
        console.log("err", err)
        return { success: false, data: {} }
    }
}

async function getAllBranchPatients(sortKey: string, sortOrder: string, filters?: { [key: string]: Array<string> }, debouncedSearch?: string) {
    try {
        const patients = await makePostRequest(`patients/get-all-site-patients`, { sortKey, sortOrder, filters })
        if (patients.data.success)
            return patients.data.data
    } catch (err) {
        console.log("err", err);
        return { success: false, data: {} }
    }
}

async function getAllBranchPatientsWithClinical(sortKey: string, sortOrder: string) {
    try {
        const patients = await makePostRequest(`patients/all-site-patients-with-clinical`, { sortKey, sortOrder })
        if (patients.data.success)
            return patients.data.data
    } catch (err) {
        console.log("err", err);
        return { success: false, data: {} }
    }
}


async function getBranchPatientCounts() {
    try {
        const patients = await makePostRequest(`patients/get-all-site-patients`, { count: true })
        if (patients.data.success)
            return patients.data.data
    } catch (err) {
        console.log("err", err);
        return { success: false, data: {} }
    }
}

async function getPatient(identityNumber: string) {
    try {
        const patients = await makePostRequest(`patients/get-patient`, { identityNumber });
        if (patients.data)
            return patients.data
    } catch (err) {
        console.log("err", err);
        return { success: false, data: {} }
    }
}

async function createPatient(body: CreatePatientInterface) {
    try {
        const patient = await makePostRequest(`patients/create-patients`, body);
        if (patient.data)
            return patient.data
    } catch (err) {
        return { success: false, data: { errorMessage: getErrorMessage(err) } };
    }
}

async function updatePatient(body: CreatePatientInterface, patientId: string) {
    try {
        const patient = await makePostRequest(`patients/update/${patientId}`, body);
        if (patient.data)
            return patient.data
    } catch (err) {
        return { success: false, data: { errorMessage: getErrorMessage(err) } };
    }

}

async function updatePatientClinical(body: CreatePatientClinicalInterface, patientId: string) {
    try {
        const patient = await makePostRequest(`clinical-info/patient/update/${patientId}`, body);
        if (patient.data)
            return patient.data
    } catch (err) {
        return { success: false, data: { errorMessage: getErrorMessage(err) } };
    }
}

async function createPatientClinical(body: CreatePatientClinicalInterface) {
    try {
        const patient = await makePostRequest(`clinical-info/patient`, body);
        if (patient.data)
            return patient.data
    } catch (err) {
        return { success: false, data: { errorMessage: getErrorMessage(err) } };
    }
}


async function getPatientClinical(patientId: string) {
    try {
        const clinicalInfo = await makeGetRequest(`clinical-info/patient/${patientId}`);
        if (clinicalInfo.data)
            return clinicalInfo.data;
    } catch (err) {
        console.log("err", err);
        return { success: false, data: {}, message: err };
    }
}


async function getSitesData(
    sortKey: string,
    sortOrder: string,
    filters?: { [key: string]: Array<string> },
    search?: string
) {
    try {
        const response = await makeGetRequest(`site/get-all`, {
            sortKey,
            sortOrder,
            filters,
            search,
        });
        if (response.data) {
            return response.data;
        } else {
            return { success: false, data: {} }; //TODO add error handling? some tooltip?
        }
    } catch (error) {
        console.log("error", error); //TODO add error handling? some tooltip?
        return { success: false, data: {} };
    }
};

async function createNewSite(body: SiteProps) {
    try {
        const response = await makePostRequest(`site/create`, body);
        if (response.data) {
            return response.data;
        } else {
            return { success: false, data: {} };
        }
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error) } };
    }
}

async function updateSite(body: SitePropsWithOptionalEmail) {
    try {
        const response = await makePostRequest(`site/update`, body);
        if (response.data) {
            return response.data;
        } else {
            return { success: false, data: {} };
        }
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error) } };
    }
}

async function deleteSite(ids: string[]) {
    try {
        const response = await makePostRequest(`site/delete`, { idsToDelete:ids });
        if (response.data) {
            return response.data;
        } else {
            return { success: false, data: {} };
        }
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error) } };
    }
}

async function getUsersData(params:BaseFetchOptions) {
    try {
        const response = await makeGetRequest(`users/get-users`, params);
        if (response.data) {
            return response.data;
        } else {
            return { success: false, data: {} };
        }
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error) } };
    }
}

async function createUser(body: UserCreateInterface) {
    try {
        const response = await makePostRequest(`users/create-user`, body);
        if (response.data) {
            return response.data;
        } else {
            return { success: false, data: {} };
        }
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error) } };
    }
}

async function getUserDataForCreation() {
    try {
        const response = await makeGetRequest(`users/user-creation-data`);
        if (response.data) {
            return response.data;
        } else {
            return { success: false, data: {} };
        }
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error) } };
    }
}

async function updateUser(body: UserCreateInterface) {
    try {
        const response = await makePostRequest(`users/update-user`, body);
        if (response.data) {
            return response.data;
        } else {
            return { success: false, data: {} };
        }
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error) } };
    }
}

async function deleteUsers(ids: string[]) {
    try {
        const response = await makePostRequest(`users/delete-users`, { idsToDelete:ids });
        if (response.data) {
            return response.data;
        } else {
            return { success: false, data: {} };
        }
    } catch (error) {
        return { success: false, data: { errorMessage: getErrorMessage(error) } };
    }
}

export {
    postLogin,
    getAllBranchPatientsWithClinical,
    postSetFirstPassword,
    postPersonalInfo,
    getUserInfo,
    createPatientClinical,
    getPatientClinical,
    getAllBranchPatients,
    getPatient,
    getBranchPatientCounts,
    createPatient,
    getSitesData,
    createNewSite,
    updateSite,
    deleteSite,
    getUserByEmailForSite,
    getUsersData,
    createUser,
    updateUser,
    getUserDataForCreation,
    deleteUsers,
    updatePatient,
    updatePatientClinical
};