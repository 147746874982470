
type AddressFormatType = {
    address: string;
    city: string;
    zip?: string;
    state?: string;
    country: string;
}

export const addressFormat = (address: AddressFormatType): string => {
    let fullAddress = "";
  
    if (address.address) {
      fullAddress += address.address;
    }
  
    if (address.city) {
      if (fullAddress) fullAddress += ", ";
      fullAddress += address.city;
    }
  
    if (address.state) {
      if (fullAddress) fullAddress += ", ";
      fullAddress += address.state;
    }
  
    if (address.zip) {
      if (fullAddress) fullAddress += ", ";
      fullAddress += address.zip;
    }
  
    if (address.country) {
      if (fullAddress) fullAddress += ", ";
      fullAddress += address.country;
    }
  
    return fullAddress;
  };